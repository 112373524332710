<template>
  <div class="body">
    <div class="logo">
      <img style="width: 200px" src="../assets/logo.png" alt="logo">
    </div>
    <div>
      <h1>Code Snippet <br> Manager</h1>
    </div>
    <div>
      <div v-if="error" class="error-msg">{{ error }}</div>
      <form action="#" @submit.prevent="handleResetPassword">
        <div>
          <div class="input-fields" style="padding-bottom: 10px">
            <input
                id="password"
                type="password"
                class="form-control"
                name="password"
                placeholder="Password"
                value
                required
                autofocus
                v-model="Password"
            />
            <input
                id="confirmPassword"
                type="password"
                class="form-control"
                name="confirmPassword"
                placeholder="Confirm Password"
                value
                required
                autofocus
                v-model="Password"
            />
          </div>
        </div>
        <div class="btn-wrapper">
          <div class="btn-container">
            <div class="btn-loading">
              <div v-if="this.loadingStatus === 'LOADING'" class="loading-animation">
                <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
              </div>
              <button type="submit">Reset password</button>
            </div>
          </div>
          <div class="login-container">
            <a href="#" @click="this.$router.push('login')" class="login-link">Login</a>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="footer-container">
    <footer>
      <div class="footer-link-container">
        <a href="https://codesnippetmanager.com/imprint" class="footer-link">Imprint</a> |
        <a href="https://codesnippetmanager.com/privacy-policy" class="footer-link">Privacy Policy</a>
      </div>
    </footer>
  </div>
</template>

<script>
import { confirmPasswordReset } from 'firebase/auth';
import { auth } from '@/firebaseConfig';
import router from "@/router";

export default {
  name: "ResetPassword",
  data() {
    return {
      loadingStatus: 'LOADED',
    }
  },
  methods: {
    async handleResetPassword() {
      this.loadingStatus = 'LOADING';
      const authCode = this.$route.query.authCode;
      const newPassword = document.querySelector('#password').value

      try {
        await confirmPasswordReset( auth,authCode, newPassword);
        this.loadingStatus = 'LOADED';
        await router.push({path: '/login'})
      } catch(err) {
        console.error(err)
      }
    }
  }
}
</script>

<style scoped>

.body {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

h1 {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 36px;
  line-height: 33px;
  margin: 25px 0 35px 0;
  text-align: center;
  color: #FFFFFF;
}

.logo {
  width: 200px;
  margin: 0 auto;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

input {
  height: 2.5rem;
  padding: 0 15px;
  border-radius: 18px;
  width: 414px;
  box-sizing: border-box;
  background-color: #2C2C2C;
  border: none;
}

input,select {
  color: white;
  font-family: "Open Sans", sans-serif;
}

input::placeholder {
  color: #9EA5AD;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  line-height: 17px;
  font-size: 14px;
  font-weight: 400;
}

input:focus-visible {
  outline: 2px solid #36B37E;
  outline-offset: -2px;
}

.input-fields {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

button {
  width: 202px;
  height: 51px;
  border-radius: 24px;
  border: none;
  background-color: #36B37E;
  color: white;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 93%;
  cursor: pointer;
}

button:hover {
  background-color: #FFFFFF;
  color: #36B37E;
}

.btn-container {
  margin-top: 25px;
}

a {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  text-decoration: none;
  color: white;
}

.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.login-link {
  font-size: 18px;
  font-weight: 700;
  color: #FFFFFF;
  margin-top: 15px;
}

.login-link:hover {
  color: #36B37E;
}

.error-msg {
  color: #FF5F56;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 120%;
  margin: 0 0 5px 15px;
}

footer {
  padding: 15px;
  background-color: #2C2C2C;
  flex-shrink: 0;
}

.footer-link-container {
  color: white;
  font-weight: bold;
  display: flex;
  justify-content: center;
}

.footer-container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.footer-link {
  font-size: 16px;
  cursor: pointer;
}

.footer-link:hover {
  color: #36B37E;
}

.btn-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  margin: 4px;
  border: 4px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.btn-loading {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}
</style>