<template>
  <div ref="container">
  </div>
</template>

<script>

import CodeSnippet from './CodeSnippet.vue'
import { defineComponent, createApp } from 'vue'
import { codeSnippetSysthem } from '../../globalVar.js'
export default {
  data() {
    return {
      startX: codeSnippetSysthem.startX,
      startY: codeSnippetSysthem.startY,
      rowAmount: codeSnippetSysthem.rowAmount,
    }
  },
  props: {
    projectId: String,
  },
  components: {
  },
  methods: {
    createCodeSnippet() {
      let _title = prompt("EnterTitle", "Title");
      let _codeContent = prompt("Enter Code", "myArray.push('hello')");
      let _date = prompt("Enter Date", "21.7.2001");
      codeSnippetSysthem.codeSnippetarray.push(
        {
          title: _title,
          codeContent: _codeContent,
          date: _date,
        }
      )
      codeSnippetSysthem.checkUpdate = true
      this.RenderCodeSnippet();

    },
    RenderCodeSnippet() {
      if (codeSnippetSysthem.checkUpdate == true) {
        try {
          let delElement = document.getElementById("codeJS");
          delElement.remove();
          delElement = document.getElementById("codeCss");
          delElement.remove();
        } catch (err) {
          console.log("rmv")
        }
        let element = document.createElement('script')
        element.setAttribute('src', 'https://swompygames.com/prism.js')
        element.setAttribute('id', 'codeJS');
        document.head.appendChild(element)
        element = document.createElement('link')
        element.setAttribute('rel', 'stylesheet')
        element.setAttribute('href', 'https://swompygames.com/prism.css')
        element.setAttribute('id', 'codeCss');
        document.head.appendChild(element)

        this.$refs.container.innerHTML = '';
        for (let i = 0; i < codeSnippetSysthem.codeSnippetarray.length; i++) {
          let buttonView;
          let currentPosition = i;
          let _setTop = this.startX + (currentPosition % this.rowAmount) * codeSnippetSysthem.rasterStep;
          let _setLeft = this.startY + Math.floor(currentPosition / this.rowAmount) * codeSnippetSysthem.rowStep;
          buttonView = defineComponent({
            extends: CodeSnippet, data() {
              return {
              }
            }
          })
          const CodeSnippetElement = document.createElement('CodeSnippet');
          CodeSnippetElement.addEventListener('mouseup', this.RenderCodeSnippet);
          this.$refs.container.appendChild(CodeSnippetElement);
          createApp(buttonView, {
            setTop: _setLeft.toString(),
            setLeft: _setTop.toString(),
            date: codeSnippetSysthem.codeSnippetarray[i].date,
            language: codeSnippetSysthem.codeSnippetarray[i].language,
            title: codeSnippetSysthem.codeSnippetarray[i].title,
            codeContent: codeSnippetSysthem.codeSnippetarray[i].codeContent,
            snippetId: codeSnippetSysthem.codeSnippetarray[i].snippetId,
            codeSnippetPosition: currentPosition,
            projectId: this.projectId,
            description: codeSnippetSysthem.codeSnippetarray[i].description,
          }).mount(CodeSnippetElement)
        }
      }
      codeSnippetSysthem.checkUpdate = false;
    }

  },
  mounted() {
    console.log(this.$props.projectId)
    fetch('https://europe-west3-code-snippet-manager-d495f.cloudfunctions.net/api/databaseGetCodeSnippet', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "projectId": this.$props.projectId,
      })
    })
      .then(response => response.json())
      .then(data => {
        let arr = Object.values(data[1]);
        let newArr = arr.map((val, index) => {
          val.snippetId = Object.keys(data[1])[index];
          return val;
        });
        let sortArr = data[0];
        let filteredArray;
        if (sortArr != "no Code Snippet Order") {
          filteredArray = newArr.sort(function (a, b) {
            return sortArr.indexOf(a.snippetId) - sortArr.indexOf(b.snippetId);
          });
        }
        else {
          filteredArray = newArr;
        }
        console.log(filteredArray)
        codeSnippetSysthem.codeSnippetarray = filteredArray;
        codeSnippetSysthem.checkUpdate = true;
        localStorage.setItem('codeSnippetarray', JSON.stringify(filteredArray));
        this.RenderCodeSnippet()
      })
      .catch((error) => {
        console.error('Error:', error);
      });

  },
}
</script>

<style scoped>

</style>
