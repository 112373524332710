<template>
  <Suspense>
    <div>
      <div class="container">
        <div class="container-2">
          <div class="profile-wrapper">
            <img class="profile-icon" ref="profileIcon" src="../assets/user.png" @click="toggleProfileDialog">
          </div>
          <div class="personal-project-header-wrapper">
            <h2 class="dashboard-header">{{ this.projectDetails.name }}</h2>
            <img class="add-snippet" src="../assets/add.svg" @click="goToAdd">
          </div>
        </div>
      </div>
      <div class="expand-menu-content" v-if="isProfileDialogVisible" ref="profileDialog">
        <div class="outer-team-wrapper">
          <div v-for="(teams) in this.currentTeams" :key="teams" class="team-wrapper">
            <p class="team-label">{{ teams.name }}</p>
            <img v-if="isUserAdmin(teams)" class="leave-team" alt="leave-team" src="../assets/logout.png"
              @click="leaveTeam(teams.id)">
          </div>
        </div>
        <div class="divider"></div>
        <div class="btn-wrapper">
          <button @click="logOut" class="logout-btn">Logout</button>
        </div>
      </div>
    </div>
  </Suspense>
  <CodeSnippetDiv :projectId="projectId" />
</template>

<script>
import router from "@/router";
import CodeSnippetDiv from "./CodeSnippetDiv.vue";
import { codeSnippetSysthem } from '../../globalVar.js'
import { logout } from "@/firebaseConfig";

export default {
  name: 'projectPage',
  props: {
    projectId: {
      type: String,
      required: true
    },
    teamName: String,
    userId: String,
    isSolo: String,
  },
  components: {
    CodeSnippetDiv
  },
  data() {
    return {
      isProfileDialogVisible: false,
      isCreateTeamDialogVisible: false,
      projectDetails: {},
      currentTeams: this.$store.getters['user/getCurrentTeams']
    }
  },
  watch: {
    projectId: {
      immediate: true,
      handler(projectId) {
        this.loadProjectDetails(projectId);
      }
    }
  },
  methods: {
    async logOut() {
      await logout();
      this.$router.replace({ name: 'login' });
    },
    async loadProjectDetails(projectId) {
      const response = await fetch('https://europe-west3-code-snippet-manager-d495f.cloudfunctions.net/api/databaseGetProjectData', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ projectId })
      })

      if (response.status === 200) {
        this.projectDetails = await response.json()
        console.log(this.projectDetails.isTeamProject)
      }
    },
    toggleProfileDialog() {
      this.isProfileDialogVisible = this.isProfileDialogVisible !== true;
    },
    showCreateTeamDialog() {
      this.isCreateTeamDialogVisible = true;
    },
    isUserAdmin(team) {
      const adminUid = team.user[Object.keys(team.user)[0]].userId
      return adminUid !== this.getUserId;
    },
    goToAdd() {
      window.location.href = window.location.href + "/add";
    },
    async leaveTeam(id) {
      try {
        const response = await fetch('https://europe-west3-code-snippet-manager-d495f.cloudfunctions.net/api/leaveTeam', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            userId: this.getUserId,
            teamId: id
          })
        });
        if (response.status === 204) {
          await router.push('/');
          await location.reload();
        }
      } catch (err) {
        console.error(err)
      }
    }
  },
  computed: {
    getUserId() {
      return this.$store.getters['auth/getUserId'];
    }
  },
  mounted() {

    codeSnippetSysthem.isSolo = this.$props.isSolo;

    codeSnippetSysthem.userId = this.$store.getters['auth/getUserId']
  },
  async created() {
    try {
      await this.$store.dispatch('user/loadCurrentTeams');
      await this.$store.dispatch('user/loadPersonalProjects');
      this.$store.dispatch('user/loadFavoriteProjects');
    } catch (err) {
      console.error(err);
    }
  }
}
</script>

<style scoped>
.container {
  display: grid;
  grid-template-columns: 350px 1fr;
}

.container-2 {
  grid-column-start: 2;
}

.search-bar-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
}

.personal-project-header-wrapper {
  display: flex;
  gap: 1rem;
}

.dashboard-header {
  color: #FFFFFF;
  font-size: 32px;
  line-height: 93%;
  font-weight: 600;
}

.profile-wrapper {
  position: absolute;
  top: 25px;
  right: 150px;
}

.profile-icon {
  cursor: pointer;
  width: 50px;
}

.expand-menu-content {
  position: absolute;
  top: 95px;
  right: 150px;
  width: 333px;
  background: #2C2C2C;
  border: 1px solid #1E1E1E;
  border-radius: 16px;
  z-index: 999;
}

.btn-wrapper {
  display: flex;
  justify-content: center;
}

.create-team-btn {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #36B37E;
  border: none;
  background: none;
}

.divider {
  height: 0;
  border: 1px solid #1E1E1E;
  margin: 10px 0;
}

.outer-team-wrapper {
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin: 20px 0;
}

.team-label {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #FFFFFF;
  margin: 0;
}

.leave-team {
  width: 19px;
  height: 19px;
}

.leave-team:hover {
  cursor: pointer;
}

.logout-btn {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #9EA5AD;
  border: none;
  background: none;
  margin-bottom: 10px;
}

.logout-btn:hover {
  cursor: pointer;
}

.team-wrapper {
  display: flex;
  justify-content: space-between;
  margin: 0 15px 0 25px;
}

.add-snippet {
  cursor: pointer
}
</style>