import { createStore } from 'vuex';
import user from './user';
// eslint-disable-next-line
import auth from './auth';

export default createStore({
    modules: {
        user,
        auth
    }
});
