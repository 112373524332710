<template>
  <div class="project-tile">
    <div class="star-wrapper">
      <img v-if="this.isFavoriteProject" src="../assets/star-full.svg" class="star" @click="removeFavoriteProject">
      <img v-if="!this.isFavoriteProject" src="../assets/star-empty.svg" class="star" @click="addFavoriteProject()">
    </div>
    <div class="project-information-wrapper">
      <div class="create-date-wrapper">
        <h3 class="create-date">{{ this.project.createDate }}</h3>
      </div>
      <div class="project-title-wrapper">
        <h4 @click="openProjectPage()" class="project-title">{{ this.project.name }}</h4>
        <img src="../assets/edit.svg" class="edit-icon" @click="showProjectSettings(this.projectId)">
      </div>
      <div class="number-of-snippets-wrapper">
        <p class="number-of-snippets">{{ this.project.numberOfCodeSnippets }} Code Snippets</p>
      </div>
    </div>
  </div>
</template>

<script>
import projectPage from "@/components/projectPage";
import router from "@/router";
export default {
  name: "projectTile",
  components: {
    // eslint-disable-next-line
    projectPage,
  },
  data() {
    return {
      isFavoriteProject: this.project.favorite,
      projectNameInputValue: '',
    }
  },
  props: {
    project: {
      type: Object,
      required: true
    },
    teamName: {
      type: String,
      required: false
    },
    parentTeamId: {
      type: String,
      required: false
    }
  },
  methods: {
    async addFavoriteProject() {
      if (!this.project.isTeamProject){
        const response = await fetch('https://europe-west3-code-snippet-manager-d495f.cloudfunctions.net/api/databasePatchAddToFavoriteProject', {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({
            projectId: this.project.id
          })
        });
        if(response.status === 204){
          // eslint-disable-next-line
          this.isFavoriteProject = true;
          location.reload();
        }
      } else {
        const response = await fetch('https://europe-west3-code-snippet-manager-d495f.cloudfunctions.net/api/databasePatchAddToFavoriteTeamProject',{
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({
            userId: this.getUserId,
            teamProjectId: this.project.id
          })
        });
        if (response.status === 204) {
          this.isFavoriteProject = true;
          location.reload();
        }
      }
    },
    async removeFavoriteProject() {
      if (!this.project.isTeamProject){
        const response = await fetch('https://europe-west3-code-snippet-manager-d495f.cloudfunctions.net/api/databasePatchDeleteToFavoriteProject', {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({
            projectId: this.project.id
          })
        });
        if(response.status === 204){
          // eslint-disable-next-line
          this.isFavoriteProject = false;
          location.reload();
        }
      } else {
        const response = await fetch('https://europe-west3-code-snippet-manager-d495f.cloudfunctions.net/api/databasePatchDeleteToFavoriteTeamProject',{
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({
            userId: this.getUserId,
            projectId: this.project.id
          })
        });
        if (response.status === 204) {
          this.isFavoriteProject = false;
          location.reload();
        }
      }
    },
    openProjectPage() {
      if (!this.project.isTeamProject) {
        router.push({
          name: 'personalProjectPage',
          params: { projectId: this.project.id, userId: this.getUserId}
        });
      } else {
        router.push({
          name: 'teamProjectPage',
          params: { teamName: this.teamName, projectId: this.project.id, userId: this.getUserId},
        });
      }
    },
    showProjectSettings() {
      this.$emit('showProjectSettings');
    }
  },
  computed: {
    getUserId() {
      return this.$store.getters['auth/getUserId'];
    }
  }
}
</script>

<style scoped>
.project-tile {
  background: #2C2C2C;
  border-radius: 16px;
}

.project-tile:hover {
  outline: 2px solid #36B37E;
  outline-offset: -2px;
}

.star {
  cursor: pointer;
}

.star-wrapper {
  display: flex;
  justify-content: flex-end;
  padding: 15px 15px 0 0;
}

.project-information-wrapper {
  padding: 30px 0 65px 50px;
}

.create-date {
  font-weight: 400;
  font-size: 12px;
  line-height: 93%;
  color: #9EA5AD;
  margin: 0;
}

.project-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 93%;
  color: #FFFFFF;
  margin: 0;
  cursor: pointer;
}

.project-title:hover {
  color: #36B37E;
}

.number-of-snippets {
  font-weight: 400;
  font-size: 16px;
  line-height: 93%;
  color: #9EA5AD;
  margin: 0;
}

.project-title-wrapper {
  display: flex;
  gap: 1rem;
  padding: 15px 0;
}

.edit-icon {
  cursor: pointer;
  margin-bottom: 10px;
}

.dialog-header {
  font-weight: 700;
  font-size: 28px;
  line-height: 93%;
  color: #FFFFFF;
}

.input-container {
  display: flex;
  flex-direction: column;
}

.dialog-input-label {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 93%;
  color: #FFFFFF;
  padding: 20px 0 10px 10px;
}

.dialog-input-field {
  width: 100%;
  height: 37px;
  background: #2C2C2C;
  border-radius: 18px;
  border: none;
  padding-left: 10px;
  color: #FFFFFF;
}

.dialog-input-field:focus-visible {
  outline-offset: 1px;
  outline: 1px solid #36B37E;
}
</style>