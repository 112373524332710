export default {
    async loadCurrentTeams({ commit, rootGetters }) {
        const userId = rootGetters['auth/getUserId'];
        const teamNameResponse = await fetch('https://europe-west3-code-snippet-manager-d495f.cloudfunctions.net/api/databaseGetTeam', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ userId })
        });
        const currentTeams = [];

        if (teamNameResponse.status === 200) {
            const teamNames = await teamNameResponse.json();
            for (const teamId of Object.keys(teamNames)) {
                const teamProjectsResponse = await fetch('https://europe-west3-code-snippet-manager-d495f.cloudfunctions.net/api/databaseGetTeamProject', {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({
                        teamId,
                        userId
                    })
                })
                if (teamProjectsResponse.status === 200){
                    const teamProjects = await teamProjectsResponse.json();
                    let teamProjectArray = [];
                    for (const teamProject of Object.keys(teamProjects)) {
                        teamProjectArray.push({
                            id: teamProject,
                            name: teamProjects[teamProject].name,
                            createDate: teamProjects[teamProject].createDate,
                            numberOfCodeSnippets: teamProjects[teamProject].numberOfCodeSnippets,
                            isTeamProject: true,
                            parentTeamId: teamProjects[teamProject].teamId,
                            favorite: teamProjects[teamProject].favorite
                        })
                    }
                    currentTeams.push({
                        id: teamId,
                        name: teamNames[teamId].name,
                        user: teamNames[teamId].user,
                        projects: teamProjectArray
                    })
                } else {
                    currentTeams.push({
                        id: teamId,
                        name: teamNames[teamId].name,
                        user: teamNames[teamId].user,
                        projects: []
                    })
                }
            }
        }

        console.log(currentTeams);

        commit('setCurrentTeams', currentTeams);
    },
    async loadPersonalProjects({ commit, rootGetters }) {
        const userId = rootGetters['auth/getUserId'];
        const personalProjectsResponse = await fetch('https://europe-west3-code-snippet-manager-d495f.cloudfunctions.net/api/databaseGetProject', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ userId })
        });
        const personalProjects = [];

        if (personalProjectsResponse.status === 200){
            const tempPersonalProjects = await personalProjectsResponse.json();
            for (const tempPersonalProject of Object.entries(tempPersonalProjects)) {
                personalProjects.push({
                    id: tempPersonalProject[0],
                    createDate: tempPersonalProject[1].createDate,
                    favorite: tempPersonalProject[1].favorite,
                    name: tempPersonalProject[1].name,
                    numberOfCodeSnippets: tempPersonalProject[1].numberOfCodeSnippets,
                });
            }
        }

        for (const personalProject of personalProjects){
            personalProject.isTeamProject = false;
        }

        console.log(personalProjects)

        commit('setPersonalProjects', personalProjects);
    },
    async loadFavoriteProjects({ commit, rootGetters }) {
        const userId = rootGetters['auth/getUserId'];
        const teamNameResponse = await fetch('https://europe-west3-code-snippet-manager-d495f.cloudfunctions.net/api/databaseGetTeam', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ userId })
        });
        const favoriteProjects = [];

        if (teamNameResponse.status === 200) {
            const teamNames = await teamNameResponse.json();

            for (const teamId of Object.keys(teamNames)) {
                const teamProjectsResponse = await fetch('https://europe-west3-code-snippet-manager-d495f.cloudfunctions.net/api/databaseGetTeamProject', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        teamId,
                        userId
                    })
                })
                if (teamProjectsResponse.status === 200){
                    const teamProjects = await teamProjectsResponse.json();
                    for (const teamProject of Object.keys(teamProjects)) {
                        if (teamProjects[teamProject].favorite) {
                            favoriteProjects.push({
                                id: teamProject,
                                name: teamProjects[teamProject].name,
                                createDate: teamProjects[teamProject].createDate,
                                numberOfCodeSnippets: teamProjects[teamProject].numberOfCodeSnippets,
                                parentTeamId: teamProjects[teamProject].teamId,
                                isTeamProject: true,
                                favorite: teamProjects[teamProject].favorite,
                                teamName: teamNames[teamId].name
                            })
                        }
                    }
                }
            }
        }

        const personalProjects = [];

        const personalProjectsResponse = await fetch('https://europe-west3-code-snippet-manager-d495f.cloudfunctions.net/api/databaseGetProject', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ userId })
        })
        if (personalProjectsResponse.status === 200){
            personalProjects.push(await personalProjectsResponse.json());
        }

        if (personalProjects) {
            for (const personalProject of personalProjects) {
                if (personalProject[Object.keys(personalProject)[0]].favorite) {
                    favoriteProjects.push({
                        id:Object.keys(personalProject)[0],
                        name: personalProject[Object.keys(personalProject)[0]].name,
                        createDate: personalProject[Object.keys(personalProject)[0]].createDate,
                        numberOfCodeSnippets: personalProject[Object.keys(personalProject)[0]].numberOfCodeSnippets,
                        favorite: personalProject[Object.keys(personalProject)[0]].favorite,
                        isTeamProject: false
                    })
                }
            }
        }

        console.log(favoriteProjects);

        commit('setFavoriteProjects', favoriteProjects);
    }
}
