import { createRouter, createWebHistory } from 'vue-router';
import { isLoggedIn } from '@/firebaseConfig';
import Login from '@/views/Login';
import Register from '@/views/Register';
import Dashboard from '@/views/Dashboard';
import forgotPassword from '@/views/ForgotPassword';
import AddCodeSnippet from '@/components/AddCodeSnippet';
import Auth from '@/views/Auth';
import resetPassword from '@/views/ResetPassword';
import JoinTeam from '@/views/JoinTeam';
import projectPage from '@/components/projectPage.vue';

const routes = [
    {
        path: '/login',
        name: 'login',
        component: Login
    },
    {
        path: '/register',
        name: 'register',
        component: Register
    },
    {
        path: '/',
        name: 'dashboard',
        component: Dashboard,
        meta: { requiresAuth: true }
    },
    {
        path: '/forgot-password',
        name: 'forgotPassword',
        component: forgotPassword
    },
    {
        path: '/auth',
        name: 'auth',
        component: Auth
    },
    {
        path: '/reset-password',
        name: 'resetPassword',
        component: resetPassword
    },
    {
        path: '/jointeam',
        name: 'joinTeam',
        component: JoinTeam,
    },
    {
        path: '/personal/:projectId',
        name: 'personalProjectPage',
        component: projectPage,
        meta: { requiresAuth: true },
        props: route => ({
            projectId: route.params.projectId,
            isSolo : true
        })
    },
    {
        path: '/:teamName/:projectId/add',
        name: 'AddCodeSnippetTeam',
        component: AddCodeSnippet,
        meta: { requiresAuth: true },
        props: route => ({
            projectId: route.params.projectId,
            snippetId : route.query.snippetId || 'noId',
        })
    },
    {
        path: '/personal/:projectId/add',
        name: 'AddCodeSnippet',
        component: AddCodeSnippet,
        meta: { requiresAuth: true },
        props: route => ({
            projectId: route.params.projectId,
            snippetId : route.query.snippetId || 'noId',
        })
    },
    {
        path: '/:teamName/:projectId',
        name: 'teamProjectPage',
        component: projectPage,
        meta: { requiresAuth: true },
        props: route => ({
            projectId: route.params.projectId,
            isSolo : false
        })
    },
    {
        path: '/:notFound(.*)*',
        redirect: { name: 'dashboard' }
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
});

router.beforeEach(async (to, from, next) => {
    const routeRequiresAuth = to.matched.some(record => record?.meta.requiresAuth);
    await isLoggedIn() || !routeRequiresAuth ? next() : next({ name: 'login' });
});

export default router;