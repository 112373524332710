<template>
  <div class="sidebar">
    <div class="sidebar-wrapper">
      <div class="logo" @click="openDashboard">
        <img style="width: 160px" src="../assets/logo.png">
      </div>
      <div class="nav-wrapper">
        <h3>My pinned projects</h3>
        <div class="menu">
          <span @click="openFavoriteProjects(project.id, project.isTeamProject, project.teamName)"
            v-for="project in favoriteProjects" :key="project" class="text">{{ project.name }}</span>
        </div>
        <h3>My projects</h3>
        <div class="menu">
          <span @click="openPersonalProject(project.id)" class="text" v-for="project in personalProjects"
            :key="project">{{ project.name }}</span>
        </div>
        <div v-for="(object) in currentTeams" :key="object">
          <div class="team-container">
            <h3 class="team">{{ object.name }}</h3>
            <img @click="showIndividualTeamSettingsDialog(object.name, object.id, object)" src="../assets/settings.png"
              class="settings-icon">
          </div>
          <div class="menu">
            <span @click="openTeamProject(object.name, project.id)" class="text" v-for="(project) in object.projects"
              :key="project">{{ project.name }}</span>
          </div>
        </div>
      </div>
      <div class="profile-wrapper">
        <div class="profile-information">
          <h4>{{ name }}</h4>
          <p>{{ email }}</p>
          <span @click="showProfileSettingsDialog" class="Settings">Profile settings</span>
        </div>
      </div>
    </div>
  </div>
  <BaseDialog v-if="isProfileSettingsDialogVisible" :isVisible="isProfileSettingsDialogVisible"
    @update:isVisible="isProfileSettingsDialogVisible = $event" @show="showProfileSettingsDialog"
    @hide="hideProfileSettingsDialog" ref="dialog">
    <h2>Profile Settings</h2>
    <img class="profile-icon" src="../assets/user.png">
    <div class="input-container">
      <label for="Name">Name</label>
      <input type="text" name="Name" v-model="name">
    </div>
    <div class="input-container">
      <label for="E-Mail">E-Mail</label>
      <input type="email" name="E-Mail" v-model="email" disabled>
    </div>
    <div class="profile-action-container">
      <span class="profile-action" @click="showEmailConfirmationDialog">Change e-mail</span>
    </div>
    <BaseDialog v-if="isEmailConfirmationDialogVisible" :is-visible="isEmailConfirmationDialogVisible"
      @update:isVisible="isEmailConfirmationDialogVisible = $event" @show="showEmailConfirmationDialog"
      @hide="hideEmailConfirmationDialog">
      <h2 class="confirmation-header" style="font-weight: 400; font-size: 20px">Please enter your password and the new
        email to perform this action</h2>
      <div class="input-container ">
        <label for="NewEmail">New email</label>
        <input type="email" name="NewEmail" v-model="newEmail">
      </div>
      <div class="input-container confirmationInput">
        <label for="Password">Password</label>
        <input type="password" name="Password" v-model="password">
      </div>
      <div class="confirmation-btn-container">
        <span class="profile-action confirm-delete" @click="hideEmailConfirmationDialog">Cancel</span>
        <div class="btn-loading">
          <div v-if="this.changeEmailLoadingStatus === 'LOADING'" class="loading-animation">
            <div class="lds-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
          <button class="btn" @click="changeEmail">Change email</button>
        </div>
      </div>
    </BaseDialog>
    <div class="input-container">
      <label for="Password">Password</label>
      <input type="password" name="Password" disabled>
    </div>
    <div class="profile-action-container">
      <span class="profile-action" @click="showPasswordConfirmationDialog">Change password</span>
    </div>
    <BaseDialog v-if="isPasswordConfirmationDialogVisible" :is-visible="isPasswordConfirmationDialogVisible"
      @update:isVisible="isPasswordConfirmationDialogVisible = $event" @show="showPasswordConfirmationDialog"
      @hide="hidePasswordConfirmationDialog">
      <h2 class="confirmation-header" style="font-weight: 400; font-size: 20px">Please enter your password and the new
        password to perform this action</h2>
      <div class="input-container ">
        <label for="Password">Password</label>
        <input type="password" name="Password" v-model="password">
      </div>
      <div class="input-container confirmationInput">
        <label for="NewPassword">New Password</label>
        <input type="password" name="NewPassword" v-model="newPassword">
      </div>
      <div class="confirmation-btn-container">
        <span class="profile-action confirm-delete" @click="hideEmailConfirmationDialog">Cancel</span>
        <div class="loading-btn">
          <div v-if="this.changePasswordLoadingStatus === 'LOADING'" class="loading-animation"
            style="align-items: normal">
            <div class="lds-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
          <button class="btn" @click="changePassword">Change password</button>
        </div>
      </div>
    </BaseDialog>
    <div class="profile-action-container delete-container">
      <span class="profile-action delete" @click="showDeleteAccountConfirmationDialog">Delete Account</span>
    </div>
    <div class="btn-container">
      <button class="btn" @click="saveProfileInformation">Save</button>
    </div>
    <BaseDialog v-if="isDeleteAccountConfirmationDialogVisible" :is-visible="isDeleteAccountConfirmationDialogVisible"
      @update:isVisible="isDeleteAccountConfirmationDialogVisible = $event" @show="showDeleteAccountConfirmationDialog"
      @hide="hideDeleteAccountConfirmationDialog">
      <h2 class="confirmation-header">Please enter your password to perform this action</h2>
      <div class="input-container confirmationInput">
        <label for="Password">Password</label>
        <input type="password" name="Password" v-model="password">
      </div>
      <div class="confirmation-btn-container">
        <span class="profile-action confirm-delete" @click="hideDeleteAccountConfirmationDialog">Cancel</span>
        <div class="loading-btn">
          <div v-if="this.deleteAccountLoadingStatus === 'LOADING'" class="loading-animation"
            style="align-items: normal">
            <div class="lds-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
          <button class="btn" @click="deleteAccount">Delete Account</button>
        </div>
      </div>
    </BaseDialog>
  </BaseDialog>
  <BaseDialog v-if="isTeamSettingsDialogVisible" :is-visible="isTeamSettingsDialogVisible"
    @update:isVisible="isTeamSettingsDialogVisible = $event" @show="showIndividualTeamSettingsDialog"
    @hide="hideIndividualTeamSettingsDialog" ref="dialog">
    <h2>{{ this.teamSettingsName }}</h2>
    <div v-if="inviteMemberSuccessMessage" class="success-msg">
      Member has been successfully invited
    </div>
    <div v-if="inviteMemberErrorMessage" class="error-msg">
      An error has occurred, please try again
    </div>
    <div class="input-container">
      <label for="Teamname">Teamname</label>
      <input type="text" name="Teamname" v-model="teamNameInputValue">
    </div>
    <div class="input-container">
      <label class="dialog-input-label" for="memberInvite">Invite members</label>
      <input type="email" class="dialog-input-field" name="memberInvite" placeholder="E-Mail"
        v-model="inviteMemberInputValue">
      <div class="resultBox" v-if="this.inviteMemberInputValue !== ''" v-on:click="inviteMember(this.teamSettingsId)">
        <div class="loading-invite-member">
          <div v-if="this.inviteMemberLoadingStatus === 'LOADING'" class="loading-animation">
            <div class="lds-ring-small">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
          {{ result }}
        </div>
      </div>
      <div v-if="isDeleteTeamButtonVisible" class="profile-action-container delete-container"
        style="padding-bottom: 0;">
        <span class="profile-action delete" @click="showDeleteTeamConfirmationDialog">Delete Team</span>
      </div>
      <div class="submit-btn-container">
        <span class="profile-action" @click="hideIndividualTeamSettingsDialog">Cancel</span>
        <div class="loading-btn">
          <div v-if="this.changeTeamLoadingStatus === 'LOADING'" class="loading-animation">
            <div class="lds-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
          <button class="btn" @click="saveTeamSettings(this.teamSettingsId)">Save</button>
        </div>
      </div>
    </div>
  </BaseDialog>
  <BaseDialog v-if="isDeleteTeamConfirmationDialogVisible" :is-visible="isDeleteTeamConfirmationDialogVisible"
    @update:isVisible="isDeleteTeamConfirmationDialogVisible = $event" @show="showDeleteTeamConfirmationDialog"
    @hide="hideDeleteTeamConfirmationDialog">
    <h2 class="confirmation-header">Are you sure?</h2>
    <div class="confirmation-btn-container">
      <span class="profile-action confirm-delete" @click="hideDeleteTeamConfirmationDialog">Cancel</span>
      <div class="btn-loading" style="align-items: normal">
        <div v-if="this.deleteTeamLoadingStatus === 'LOADING'" class="loading-animation">
          <div class="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <button class="btn" @click="deleteTeam">Delete Team</button>
      </div>
    </div>
  </BaseDialog>
</template>

<script>
import { auth } from '@/firebaseConfig'
import BaseDialog from "@/components/BaseDialog";
import router from "@/router";
import {
  deleteUser,
  EmailAuthProvider,
  reauthenticateWithCredential,
  updateEmail,
  updatePassword,
  updateProfile
} from "firebase/auth";


export default {
  name: "TheSidebar",
  components: {
    BaseDialog
  },
  data() {
    return {
      isProfileSettingsDialogVisible: false,
      isEmailConfirmationDialogVisible: false,
      isPasswordConfirmationDialogVisible: false,
      isDeleteAccountConfirmationDialogVisible: false,
      isTeamSettingsDialogVisible: false,
      inviteMemberSuccessMessage: false,
      inviteMemberErrorMessage: false,
      isUserAdmin: false,
      isDeleteTeamButtonVisible: false,
      isDeleteTeamConfirmationDialogVisible: false,
      name: '',
      email: '',
      password: '',
      newPassword: '',
      newEmail: '',
      projectNames: [],
      teamNamesArray: [],
      teamName: '',
      inviteMemberInputValue: '',
      teamSettingsName: '',
      teamSettingsId: '',
      teamNameInputValue: '',
      loadingStatus: 'LOADED',
      changeTeamLoadingStatus: 'LOADED',
      deleteTeamLoadingStatus: 'LOADED',
      inviteMemberLoadingStatus: 'LOADED',
      changeEmailLoadingStatus: 'LOADED',
      changePasswordLoadingStatus: 'LOADED',
      deleteAccountLoadingStatus: 'LOADED',
    }
  },
  methods: {
    showProfileSettingsDialog() {
      this.isProfileSettingsDialogVisible = true;
    },
    hideProfileSettingsDialog() {
      this.isProfileSettingsDialogVisible = false;
    },
    showEmailConfirmationDialog() {
      this.isEmailConfirmationDialogVisible = true;
    },
    hideEmailConfirmationDialog() {
      this.isEmailConfirmationDialogVisible = false;
    },
    showPasswordConfirmationDialog() {
      this.isPasswordConfirmationDialogVisible = true;
    },
    hidePasswordConfirmationDialog() {
      this.isPasswordConfirmationDialogVisible = false;
    },
    showDeleteAccountConfirmationDialog() {
      this.isDeleteAccountConfirmationDialogVisible = true;
    },
    hideDeleteAccountConfirmationDialog() {
      this.isDeleteAccountConfirmationDialogVisible = false;
    },
    showDeleteTeamConfirmationDialog() {
      this.isDeleteTeamConfirmationDialogVisible = true;
    },
    hideDeleteTeamConfirmationDialog() {
      this.isDeleteTeamConfirmationDialogVisible = false;
    },
    async saveProfileInformation() {
      try {
        this.loadingStatus = 'LOADING'
        await updateProfile(auth.currentUser, {
          displayName: this.name
        });
        this.loadingStatus = 'LOADED'
      } catch (err) {
        console.error(err);
      }
      this.hideProfileSettingsDialog()
    },
    async changeEmail() {
      this.changeEmailLoadingStatus = 'LOADING';
      const credential = await EmailAuthProvider.credential(
        auth.currentUser.email,
        this.password
      );
      try {
        await reauthenticateWithCredential(auth.currentUser, credential);
      } catch (err) {
        console.error(err);
      }
      try {
        await updateEmail(auth.currentUser, this.newEmail);
      } catch (err) {
        console.error(err);
      }
      this.changeEmailLoadingStatus = 'LOADED'
      await this.hideEmailConfirmationDialog()
      await window.location.reload()
    },
    async changePassword() {
      this.changePasswordLoadingStatus = 'LOADING';
      const credential = await EmailAuthProvider.credential(
        auth.currentUser.email,
        this.password
      );
      try {
        await reauthenticateWithCredential(auth.currentUser, credential);
      } catch (err) {
        console.error(err);
      }
      try {
        await updatePassword(auth.currentUser, this.newPassword);
      } catch (err) {
        console.error(err);
      }
      this.changePasswordLoadingStatus = 'LOADED';
      this.hidePasswordConfirmationDialog()
      window.location.reload()
    },
    async deleteAccount() {
      this.deleteAccountLoadingStatus = 'LOADING';
      const credential = await EmailAuthProvider.credential(
        auth.currentUser.email,
        this.password
      );
      try {
        await reauthenticateWithCredential(auth.currentUser, credential);
      } catch (err) {
        console.error(err);
      }
      try {
        await deleteUser(auth.currentUser);
        this.deleteAccountLoadingStatus = 'LOADED';
        await router.push('/login');
      } catch (err) {
        console.error(err);
      }
    },
    createProjectNameArray: async function () {
      if (this.personalProjects !== undefined) {
        for (const array of Object.keys(this.personalProjects)) {
          this.projectNames.push(this.personalProjects[array].name)
        }
      }
    },
    openPersonalProject(projectId) {
      router.push({
        name: 'personalProjectPage',
        params: { projectId: projectId }
      }).then(() => {
        location.reload(true)
      });
    },
    openTeamProject(teamName, projectId) {
      router.push({
        name: 'teamProjectPage',
        params: { teamName: teamName, projectId: projectId }
      }).then(() => {
        location.reload(true)
      });
    },
    openFavoriteProjects(projectId, isTeamProject, teamName) {
      if (isTeamProject) {
        this.openTeamProject(teamName, projectId);
      } else {
        this.openPersonalProject(projectId);
      }
    },
    async inviteMember(id) {
      this.inviteMemberLoadingStatus = 'LOADING'
      const response = await fetch('https://europe-west3-code-snippet-manager-d495f.cloudfunctions.net/api/databasePatchInviteUser', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          email: this.result,
          teamId: id
        })
      })
      if (response.status === 204) {
        this.inviteMemberLoadingStatus = 'LOADED'
        this.inviteMemberSuccessMessage = true;
      } else {
        this.inviteMemberLoadingStatus = 'FAILED'
        this.inviteMemberErrorMessage = true;
      }
      return this.inviteMemberInputValue = '';
    },
    async showIndividualTeamSettingsDialog(teamName, teamId, team) {
      this.teamSettingsName = teamName;
      this.teamSettingsId = teamId;
      const adminUid = team.user[Object.keys(team.user)[0]].userId
      if (adminUid === this.getUserId) {
        this.isDeleteTeamButtonVisible = true;
      }
      this.isTeamSettingsDialogVisible = true;
    },
    hideIndividualTeamSettingsDialog() {
      this.teamSettingsName = '';
      this.teamSettingsId = '';
      this.isTeamSettingsDialogVisible = false;
    },
    openDashboard() {
      router.push('/')
    },
    async saveTeamSettings(teamId) {
      if (this.teamNameInputValue.length > 0) {
        try {
          this.changeTeamLoadingStatus = 'LOADING';
          await fetch('https://europe-west3-code-snippet-manager-d495f.cloudfunctions.net/api/databasePatchTeam', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              teamId: teamId,
              name: this.teamNameInputValue
            })
          })
        } catch (err) {
          this.changeTeamLoadingStatus = 'FAILED';
          console.error(err);
        }
        this.changeTeamLoadingStatus = 'LOADED';
        await router.push('/');
        await location.reload();
      } else {
        this.hideIndividualTeamSettingsDialog()
      }
    },
    async deleteTeam() {
      try {
        this.deleteTeamLoadingStatus = 'LOADING'
        const response = await fetch('https://europe-west3-code-snippet-manager-d495f.cloudfunctions.net/api/databaseDeleteTeam', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            teamId: this.teamSettingsId
          })
        })
        if (response.status === 204) {
          this.deleteTeamLoadingStatus = 'LOADED'
          await router.push('/');
          await location.reload();
        }
      } catch (err) {
        this.deleteTeamLoadingStatus = 'FAILED'
        console.error(err);
      }
    }
  },
  mounted() {
    auth.onAuthStateChanged(user => {
      this.name = user.displayName;
      this.email = user.email
    });
    this.createProjectNameArray();
  },
  computed: {
    result() {
      return this.inviteMemberInputValue;
    },
    currentTeams() {
      return this.$store.getters['user/getCurrentTeams'];
    },
    personalProjects() {
      return this.$store.getters['user/getPersonalProjects'];
    },
    favoriteProjects() {
      return this.$store.getters['user/getFavoriteProjects'];
    },
    getUserId() {
      return this.$store.getters['auth/getUserId'];
    }
  }
}
</script>

<style scoped>
.sidebar {
  width: 310px;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #2C2C2C;
}

.logo {
  display: flex;
  justify-content: center;
  margin: 30px auto 120px auto;
  cursor: pointer;
}

h3 {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  color: #9EA5AD;
  font-weight: 400;
  line-height: 15px;
  margin-top: 35px;
  margin-bottom: 10px;
}

.nav-wrapper {
  padding-left: 35px;
}

.text {
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  color: #FFFFFF;
  line-height: 22px;
}

.text:hover {
  cursor: pointer;
  font-weight: 700;
  color: #36B37E;
}

.menu {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.profile-icon {
  cursor: pointer;
  width: 50px;
}

.profile-wrapper {
  position: absolute;
  bottom: 20px;
  left: 15px;
  display: flex;
  flex-direction: column;
  padding-left: 35px;
}

h4 {
  color: #FFFFFF;
  font-weight: 700;
  line-height: 22px;
  font-size: 18px;
  margin: 0;
}

p {
  color: #9EA5AD;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  margin: 0;
}

.Settings {
  font-weight: 700;
  size: 14px;
  line-height: 17px;
  color: #36B37E;
  text-decoration: none;
}

.Settings:hover {
  cursor: pointer;
  color: #FFFFFF;
}

.settings-icon {
  width: 15px;
  height: 15px;
  margin-right: 20px;
}

.settings-icon:hover {
  cursor: pointer;
}

.team {
  margin-top: 0;
}

.team-container {
  margin-top: 35px;
  display: flex;
  justify-content: space-between;
}

.profile-information {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.expand-menu-content {
  display: none;
  position: absolute;
  left: 315px;
  width: 333px;
  height: 221px;
  bottom: 10px;
  background: #2C2C2C;
  border: 1px solid #1E1E1E;
  border-radius: 16px;
  z-index: 999;
}

.show {
  display: block !important;
}

.btn-wrapper {
  display: flex;
  justify-content: center;

}

.create-team-btn {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #36B37E;
  border: none;
  background: none;

}

.create-team-btn:hover {
  cursor: pointer;
  color: #FFFFFF;
}

.divider {
  height: 0;
  border: 1px solid #1E1E1E;
  margin: 10px 0;
}

.team-label {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #FFFFFF;
}

.outer-team-wrapper {
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin: 20px 0;
}

.team-wrapper {
  display: flex;
  justify-content: space-between;
  margin: 0 15px 0 25px;
}

.leave-team:hover {
  cursor: pointer;
}

.logout-btn {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #9EA5AD;
  border: none;
  background: none;
}

.logout-btn:hover {
  cursor: pointer;
}

.icon-btn {
  border: none;
  background: none;
  cursor: pointer;
}

h2 {
  font-weight: 700;
  font-size: 28px;
  line-height: 93%;
  color: #FFFFFF;
}

.input-container {
  display: flex;
  flex-direction: column;
}

input {
  width: 100%;
  height: 37px;
  background: #2C2C2C;
  border-radius: 18px;
  border: none;
  padding-left: 10px;
  color: #FFFFFF;
}

input:focus-visible {
  outline-offset: 1px;
  outline: 1px solid #36B37E;
}

input:disabled {
  cursor: not-allowed;
  color: #9EA5AD;
}

label {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 93%;
  color: #FFFFFF;
  padding: 20px 0 10px 10px;
}

.profile-action {
  color: #36B37E;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 93%;
  cursor: pointer;
}

.profile-action:hover {
  color: #FFFFFF;
}

.profile-action-container {
  padding: 5px 0 10px 10px;
}

.delete-container {
  padding-top: 30px;
  padding-bottom: 30px;
}

.delete {
  color: #FF4E4E;
}

.confirm-delete {
  color: #9EA5AD;
  font-size: 18px;
}

.btn-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.btn {
  width: 202px;
  height: 51px;
  border-radius: 24px;
  border: none;
  background-color: #36B37E;
  color: white;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 93%;
  cursor: pointer;
}

.btn:hover {
  background-color: #FFFFFF;
  color: #36B37E;
}

.confirmation-btn-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 12rem;
  justify-content: center;
}

.confirmation-header {
  margin: 0;
  padding: 0 0 15px 0;
  font-size: 24px;
  line-height: 26px;
}

.confirmationInput {
  padding-bottom: 20px;
}

.resultBox {
  color: #FFFFFF;
  background-color: #2C2C2C;
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  margin: 5px auto;
  width: 545px;
  z-index: 999;
  font-size: 14px;
  line-height: 13px;
  cursor: pointer;
}

.resultBox:hover {
  background-color: #36B37E;
}

.submit-btn-container {
  padding-top: 30px;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 12rem;
  justify-content: center;
}

.success-msg {
  background-color: #36B37E;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 600;
  line-height: 15px;
  text-align: center;
  padding: 20px;
  border-radius: 16px;
  margin-bottom: 30px;
}

.error-msg {
  color: #FF5F56;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 120%;
  margin: 0 0 5px 15px;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  margin: 4px;
  border: 4px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.btn-loading {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.loading-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.loading-invite-member {
  display: flex;
  gap: 1.5rem;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 17px;
  height: 17px;
}

.lds-ring-small div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 13px;
  height: 13px;
  margin: 2px;
  border: 2px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}

.lds-ring-small div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring-small div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring-small div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring-small {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>