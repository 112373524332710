<template>
  <div @click="handleClick">
    <div class="container">
      <div class="container-2">
        <div class="header-wrapper">
          <div class="profile-wrapper">
            <img class="profile-icon" ref="profileIcon" src="../assets/user.png" @click="toggleProfileDialog">
          </div>
        </div>
        <div v-if="!searchActive">
          <div class="fav-projects-wrapper">
            <div class="fav-projects-header-wrapper">
              <h2 class="dashboard-header">My pinned projects</h2>
            </div>
            <div class="project-tile-wrapper">
              <div class="project-tile-row-wrapper">
                <projectTile
                    v-for="project in this.favoriteProjects"
                    :key="project"
                    :personalProjects="this.personalProjects"
                    :currentTeams="this.currentTeams"
                    :favoriteProjects="this.favoriteProjects"
                    @showProjectSettings="showProjectSettingsDialog(project.id)"
                    :team-name="project.teamName"
                    :project="project"/>
              </div>
            </div>
          </div>
          <div class="personal-project-wrapper">
            <div class="personal-project-header-wrapper">
              <h2 class="dashboard-header">My projects</h2>
              <img class="add-project" src="../assets/add.svg" @click="showCreatePersonalProjectDialog">
            </div>
            <div class="project-tile-wrapper">
              <div class="project-tile-row-wrapper">
                <projectTile
                    v-for="project in this.personalProjects"
                    :key="project"
                    @showProjectSettings="showProjectSettingsDialog(project.id, personalProject)"
                    :project="project"
                />
              </div>
            </div>
          </div>
          <div v-for="teams in currentTeams" :key="teams" class="personal-project-wrapper">
            <div class="personal-project-header-wrapper">
              <h2 class="dashboard-header">{{ teams.name }}</h2>
              <img src="../assets/add.svg" @click="showCreateTeamProjectDialog(teams.id)">
            </div>
            <div class="project-tile-wrapper">
              <div class="project-tile-row-wrapper">
                <projectTile
                    v-for="teamProject in teams.projects"
                    :key="teamProject"
                    :teamName="teams.name"
                    :parentTeamId="teams.parentTeamId"
                    @showProjectSettings='showProjectSettingsDialog(teamProject.id, teamProject)'
                    :project="teamProject"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="expand-menu-content" v-if="isProfileDialogVisible" ref="profileDialog">
      <div style="margin: 15px 0" class="btn-wrapper">
        <button class="create-team-btn" @click="showCreateTeamDialog">Create team</button>
      </div>
      <div class="divider"></div>
      <div class="outer-team-wrapper">
        <div v-for="teams in currentTeams" :key="teams" class="team-wrapper">
          <p class="team-label">{{ teams.name }}</p>
          <img v-if="isUserAdmin(teams)" @click="leaveTeam(teams.id)" class="leave-team" alt="leave-team" src="../assets/logout.png">
        </div>
      </div>
      <div class="divider"></div>
      <div class="btn-wrapper">
        <button @click="logOut" class="logout-btn">Logout</button>
      </div>
    </div>
  </div>
  <!-- Dialogs -->
  <BaseDialog
      v-if="isCreateTeamDialogVisible"
      :is-visible="isCreateTeamDialogVisible"
      @update:isVisible="isCreateTeamDialogVisible = $event"
      @show="showCreateTeamDialog"
      @hide="hideCreateTeamDialog"
      ref="dialog"
  >
    <h2 class="dialog-header">Create team</h2>
    <div v-if="error" class="error-msg">
      {{ errorMsg }}
    </div>
    <div class="input-container">
      <label class="dialog-input-label" for="teamname">Teamname</label>
      <input type="text" class="dialog-input-field" name="teamname" v-model="teamName">
    </div>
    <div class="input-container">
      <label class="dialog-input-label" for="memberInvite">Invite members</label>
      <input type="email" class="dialog-input-field" name="memberInvite" placeholder="E-Mail" v-model="inviteMemberInputValue">
      <div class="resultBox" v-if="this.inviteMemberInputValue !== ''" v-on:click="addSelectedInviteMember">
        {{ result }}
      </div>
      <div class="selected-invite-members-wrapper">
        <ul class="selected-invite-members">
          <li v-for="selectedInviteMember in selectedInviteMembers" :key="selectedInviteMember" class="selected-invite-member">
            <div class="selected-invite-member-wrapper">
              {{ selectedInviteMember }}
              <img src="../assets/remove-user.svg" style="cursor: pointer" @click="removeUser(selectedInviteMember)">
            </div>
          </li>
        </ul>
      </div>
      <div class="submit-btn-container">
        <span class="profile-action" @click="hideCreateTeamDialog">Cancel</span>
        <div class="loading-btn">
          <div v-if="this.createTeamLoadingStatus === 'LOADING'" class="loading-animation">
            <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
          </div>
          <button class="btn" @click="submitHandler">Create team</button>
        </div>
      </div>
    </div>
  </BaseDialog>
  <BaseDialog
      v-if="isProjectSettingsDialogVisible"
      :is-visible="isProjectSettingsDialogVisible"
      @update:isVisible="isProjectSettingsDialogVisible = $event"
      @show="showProjectSettingsDialog()"
      @hide="hideProjectSettingsDialog"
      ref="dialog"
  >
    <h2 class="dialog-header">Project settings</h2>
    <div class="input-container">
      <label class="dialog-input-label" for="projectName">Name</label>
      <input type="text" class="dialog-input-field" name="projectName" v-model="projectNameInputValue">
    </div>
    <div class="profile-action-container delete-container">
      <span class="profile-action" style="color: #FF4E4E;" @click="showDeleteProjectConfirmationDialog">Delete project</span>
    </div>
    <div class="submit-btn-container">
      <span class="profile-action" @click="hideProjectSettingsDialog">Cancel</span>
      <div class="loading-btn">
        <div v-if="this.changePersonalProjectLoadingStatus === 'LOADING'" class="loading-animation">
          <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
        </div>
        <button class="btn" @click="saveProjectName">Save</button>
      </div>
    </div>
  </BaseDialog>
  <BaseDialog
      v-if="isProjectSettingsConfirmationDialogVisible"
      :is-visible="isProjectSettingsConfirmationDialogVisible"
      @update:isVisible="isProjectSettingsConfirmationDialogVisible = $event"
      @show="showDeleteProjectConfirmationDialog"
      @hide="hideDeleteProjectConfirmationDialog"
      ref="dialog"
  >
    <h2 class="confirmation-header">Are you sure?</h2>
    <div class="confirmation-btn-container">
      <span class="profile-action confirm-delete" @click="hideDeleteTeamConfirmationDialog">Cancel</span>
      <div class="loading-btn" style="align-items: normal">
        <div v-if="this.deletePersonalProjectLoadingStatus === 'LOADING'" class="loading-animation">
          <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
        </div>
        <button class="btn" @click="deleteProject">Delete project</button>
      </div>
    </div>
  </BaseDialog>
  <BaseDialog
      v-if="isCreatePersonalProjectDialogVisible"
      :is-visible="isCreatePersonalProjectDialogVisible"
      @update:isVisible="isCreatePersonalProjectDialogVisible = $event"
      @show="showCreatePersonalProjectDialog"
      @hide="hideCreatePersonalProjectDialog"
  >
    <h2 class="dialog-header">Create project</h2>
    <div class="input-container" style="padding-bottom: 30px">
      <label class="dialog-input-label" for="projectName">Name</label>
      <input type="text" class="dialog-input-field" name="projectName" v-model="newPersonalProjectNameInputValue">
    </div>
    <div class="submit-btn-container">
      <span class="profile-action" @click="hideCreatePersonalProjectDialog">Cancel</span>
      <div class="loading-btn">
        <div v-if="this.createPersonalProjectLoadingStatus === 'LOADING'" class="loading-animation">
          <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
        </div>
        <button class="btn" @click="createPersonalProject">Create project</button>
      </div>
    </div>
  </BaseDialog>
  <BaseDialog
      v-if="isCreateTeamProjectDialogVisible"
      :is-visible="isCreateTeamProjectDialogVisible"
      @update:isVisible="isCreateTeamProjectDialogVisible = $event"
      @show="showCreateTeamProjectDialog"
      @hide="hideCreateTeamProjectDialog"
  >
    <h2 class="dialog-header">Create project</h2>
    <div class="input-container" style="padding-bottom: 30px">
      <label class="dialog-input-label" for="projectName">Name</label>
      <input type="text" class="dialog-input-field" name="projectName" v-model="newTeamProjectNameInputValue">
    </div>
    <div class="submit-btn-container">
      <span class="profile-action" @click="hideCreateTeamProjectDialog">Cancel</span>
      <div class="loading-btn">
        <div v-if="this.createTeamProjectLoadingStatus === 'LOADING'" class="loading-animation">
          <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
        </div>
        <button class="btn" @click="createTeamProject">Create project</button>
      </div>
    </div>
  </BaseDialog>

  <LoadingAnimation v-if="showLoadingAnimation"/>
</template>

<script>
import ProjectTile from '@/components/projectTile';
import BaseDialog from '@/components/BaseDialog';
import LoadingAnimation from '@/components/LoadingAnimation.vue';
import router from '@/router';
import { logout } from '@/firebaseConfig';
import { LoadingStatus } from '@/scripts/enums';

export default {
  name: 'projectDashboard',
  components: {
    ProjectTile,
    BaseDialog,
    LoadingAnimation
  },
  data() {
    return {
      isProfileDialogVisible: false,
      isCreateTeamDialogVisible: false,
      searchActive: false,
      isProjectSettingsDialogVisible: false,
      isProjectSettingsConfirmationDialogVisible: false,
      isCreatePersonalProjectDialogVisible: false,
      isCreateTeamProjectDialogVisible: false,
      inviteMemberInputValue: '',
      selectedInviteMembers: [],
      teamName: '',
      errorMsg: '',
      error: false,
      personalProject: 'personalProject',
      teamProject: 'teamProject',
      projectName: '',
      projectNameInputValue: '',
      projectSettingsId: '',
      projectSettingsKindOfProject: '',
      newPersonalProjectNameInputValue: '',
      newTeamProjectNameInputValue: '',
      createTeamProjectTeamId: '',
      loadingStatus: LoadingStatus.LOADING,
      createTeamLoadingStatus: 'LOADED',
      createPersonalProjectLoadingStatus: 'LOADED',
      createTeamProjectLoadingStatus: 'LOADED',
      changePersonalProjectLoadingStatus: 'LOADED',
      changeTeamProjectLoadingStatus: 'LOADED',
      deletePersonalProjectLoadingStatus: 'LOADED',
      deleteTeamProjectLoadingStatus: 'LOADED',
    }
  },
  computed: {
    result() {
      return this.inviteMemberInputValue;
    },
    currentTeams() {
      return this.$store.getters['user/getCurrentTeams'];
    },
    personalProjects() {
      return this.$store.getters['user/getPersonalProjects'];
    },
    favoriteProjects() {
      return this.$store.getters['user/getFavoriteProjects'];
    },
    showLoadingAnimation() {
      return this.loadingStatus !== LoadingStatus.LOADED;
    },
    getUserId() {
      return this.$store.getters['auth/getUserId'];
    },
    getUserEmail() {
      return this.$store.getters['auth/getUserEmail']
    }
  },
  async created() {
    try {
      await this.$store.dispatch('user/loadCurrentTeams');
      await this.$store.dispatch('user/loadPersonalProjects');
      this.$store.dispatch('user/loadFavoriteProjects');

      this.loadingStatus = LoadingStatus.LOADED;
    } catch (err) {
      console.error(err)
      this.loadingStatus = LoadingStatus.FAILED;
    }
  },
  methods: {
    async logOut() {
      await logout();
      this.$router.replace({ name: 'login' });
    },
    toggleProfileDialog() {
      this.isProfileDialogVisible = this.isProfileDialogVisible !== true;
    },
    handleClick(event) {
      if (this.$refs.profileDialog && !this.$refs.profileDialog.contains(event.target) && !this.$refs.profileIcon.contains(event.target)) {
        this.isProfileDialogVisible = false;
      }
    },
    showCreateTeamDialog() {
      this.isCreateTeamDialogVisible = true;
    },
    hideCreateTeamDialog() {
      this.isCreateTeamDialogVisible = false;
    },
    showProjectSettingsDialog(projectId, kindOfProject) {
      this.projectSettingsId = projectId;
      this.projectSettingsKindOfProject = kindOfProject;
      this.isProjectSettingsDialogVisible = true;
    },
    hideProjectSettingsDialog() {
      this.isProjectSettingsDialogVisible = false;
    },
    showDeleteProjectConfirmationDialog() {
      this.isProjectSettingsConfirmationDialogVisible = true;
    },
    hideDeleteProjectConfirmationDialog() {
      this.isProjectSettingsConfirmationDialogVisible = false;
    },
    addSelectedInviteMember() {
      this.selectedInviteMembers.push(this.inviteMemberInputValue);
      return this.inviteMemberInputValue = '';
    },
    showCreatePersonalProjectDialog() {
      this.isCreatePersonalProjectDialogVisible = true;
    },
    hideCreatePersonalProjectDialog() {
      this.isCreatePersonalProjectDialogVisible = false;
    },
    showCreateTeamProjectDialog(teamId) {
      this.createTeamProjectTeamId = teamId
      this.isCreateTeamProjectDialogVisible = true;
    },
    hideCreateTeamProjectDialog() {
      this.isCreateTeamProjectDialogVisible = false;
    },
    removeUser(userToRemove) {
      this.selectedInviteMembers = this.selectedInviteMembers.filter(user => user !== userToRemove);
    },
    async submitHandler() {
      this.createTeamLoadingStatus = 'LOADING'
      const response = await fetch('https://europe-west3-code-snippet-manager-d495f.cloudfunctions.net/api/databasePostTeam', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          name: this.teamName,
          admin: this.getUserEmail,
          emailList: this.selectedInviteMembers
        })
      });
      if (response.status === 500) {
        this.createTeamLoadingStatus = 'FAILED'
        this.error = true;
        this.errorMsg = 'One or more users have no associated account. Please make sure to only invite people who already signed up'
      } else if (response.status === 200) {
        this.createTeamLoadingStatus = 'LOADED'
        location.reload()
      }
    },
    isUserAdmin(team) {
      const adminUid = team.user[Object.keys(team.user)[0]].userId
      return adminUid !== this.getUserId;
    },
    async leaveTeam(id) {
      try {
        const response = await fetch('https://europe-west3-code-snippet-manager-d495f.cloudfunctions.net/api/leaveTeam', {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({
            userId: this.getUserId,
            teamId: id
          })
        });
        if (response.status === 204) {
          await router.push('/');
          await location.reload();
        }
      } catch (err) {
        console.error(err);
      }
    },
    async saveProjectName() {
      if (this.projectNameInputValue.length > 0){
        if (this.projectSettingsKindOfProject === 'personal' || this.projectSettingsKindOfProject === 'personalProject') {
          try {
            this.changPersonalProjectLoadingStatus = 'LOADING';
            const response = await fetch('https://europe-west3-code-snippet-manager-d495f.cloudfunctions.net/api/databasePatchProject', {
              method: 'POST',
              headers: {'Content-Type': 'application/json'},
              body: JSON.stringify({
                projectId: this.projectSettingsId,
                name: this.projectNameInputValue
              })
            })
            if (response.status === 204) {
              this.changPersonalProjectLoadingStatus = 'LOADED';
              await router.push('/');
              await location.reload()
            }
          } catch (err) {
            this.changPersonalProjectLoadingStatus = 'FAILED';
            console.error(err);
          }
        } else {
          try {
            this.changeTeamProjectLoadingStatus = 'LOADING';
            const response = await fetch('https://europe-west3-code-snippet-manager-d495f.cloudfunctions.net/api/databasePatchTeamProject', {
              method: 'POST',
              headers: {'Content-Type': 'application/json'},
              body: JSON.stringify({
                teamProjectId: this.projectSettingsId,
                name: this.projectNameInputValue
              })
            })
            if (response.status === 204) {
              this.changeTeamProjectLoadingStatus = 'LOADED';
              await router.push('/');
              await location.reload();
            }
          } catch (err) {
            this.changeTeamProjectLoadingStatus = 'FAILED';
            console.error(err);
          }
        }
      } else {
        this.isProjectSettingsDialogVisible = false;
      }
    },
    async deleteProject() {
      if (this.projectSettingsKindOfProject === 'personal' || this.projectSettingsKindOfProject === 'personalProject') {
        try {
          this.deletePersonalProjectLoadingStatus = 'LOADING'
          const response = await fetch('https://europe-west3-code-snippet-manager-d495f.cloudfunctions.net/api/databaseDeleteTeamProject', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
              projectId: this.projectSettingsId
            })
          })
          if (response.status === 204) {
            this.deletePersonalProjectLoadingStatus = '´LOADED'
            await router.push('/');
            await location.reload();
          }
        } catch (err) {
          this.deletePersonalProjectLoadingStatus = 'FAILED'
          console.error(err);
        }
      } else {
        try {
          this.deletePersonalProjectLoadingStatus = 'LOADING'
          const response = await fetch('https://europe-west3-code-snippet-manager-d495f.cloudfunctions.net/api/databaseDeleteProject', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
              teamProjectId: this.projectSettingsId
            })
          })
          if (response.status === 204) {
            this.deletePersonalProjectLoadingStatus = 'LOADED'
            await router.push('/');
            await location.reload();
          }
        } catch (err) {
          this.deletePersonalProjectLoadingStatus = 'FAILED'
          console.error(err);
        }
      }
    },
    async createPersonalProject() {
      if (this.newPersonalProjectNameInputValue.length > 0){
        this.createPersonalProjectLoadingStatus = 'LOADING';
        const response = await fetch('https://europe-west3-code-snippet-manager-d495f.cloudfunctions.net/api/databasePostProject', {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({
            userId: this.getUserId,
            name: this.newPersonalProjectNameInputValue
          })
        })
        if (response.status === 200) {
          this.createPersonalProjectLoadingStatus = 'LOADED';
          await location.reload();
        }
      } else {
        this.createPersonalProjectLoadingStatus = 'FAILED';
        console.log('You need to select a name for the project');
      }
    },
    async createTeamProject() {
      if (this.newTeamProjectNameInputValue.length > 0) {
        this.createTeamProjectLoadingStatus = 'LOADING'
        const response = await fetch('https://europe-west3-code-snippet-manager-d495f.cloudfunctions.net/api/databasePostTeamProject', {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({
            teamId: this.createTeamProjectTeamId,
            name: this.newTeamProjectNameInputValue
          })
        })
        if (response.status === 200) {
          this.createTeamProjectLoadingStatus = 'LOADED'
          await location.reload();
        }
      } else {
        this.createTeamProjectLoadingStatus = 'FAILED'
        console.log('You need to select a name for the project');
      }
    }
  }
}

</script>

<style scoped>
.container {
  display: grid;
  grid-template-columns: 350px 1fr;
}

.container-2 {
  grid-column-start: 2;
}

.search-bar-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
}

.profile-wrapper {
  position: absolute;
  top: 25px;
  right: 150px;
}

.profile-icon {
  cursor: pointer;
  width: 50px;
}

.dashboard-header {
  color: #FFFFFF;
  font-size: 32px;
  line-height: 93%;
  font-weight: 600;
}

.fav-projects-wrapper {
  padding-top: 50px;
}

.project-tile-wrapper {
  padding: 0 150px 0 0 ;
}

.project-tile-row-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 2rem;
}

.personal-project-header-wrapper {
  display: flex;
  gap: 1rem;
}

.expand-menu-content {
  position: absolute;
  top: 95px;
  right: 150px;
  width: 333px;
  background: #2C2C2C;
  border: 1px solid #1E1E1E;
  border-radius: 16px;
  z-index: 999;
}

.btn-wrapper {
  display: flex;
  justify-content: center;
}

.create-team-btn {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #36B37E;
  border: none;
  background: none;
}

.create-team-btn:hover {
  cursor: pointer;
  color: #FFFFFF;
}

.divider {
  height: 0;
  border: 1px solid #1E1E1E;
  margin: 10px 0;
}

.outer-team-wrapper {
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin: 20px 0;
}

.team-wrapper {
  display: flex;
  justify-content: space-between;
  margin: 0 15px 0 25px;
}

.team-label {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #FFFFFF;
  margin: 0;
}

.leave-team {
  width: 19px;
  height: 19px;
}

.leave-team:hover {
  cursor: pointer;
}

.logout-btn {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #9EA5AD;
  border: none;
  background: none;
  margin-bottom: 10px;
}

.logout-btn:hover {
  cursor: pointer;
}

.dialog-header {
  font-weight: 700;
  font-size: 28px;
  line-height: 93%;
  color: #FFFFFF;
}

.input-container {
  display: flex;
  flex-direction: column;
}

.dialog-input-field {
  width: 100%;
  height: 37px;
  background: #2C2C2C;
  border-radius: 18px;
  border: none;
  padding-left: 10px;
  color: #FFFFFF;
}

.dialog-input-field:focus-visible {
  outline-offset: 1px;
  outline: 1px solid #36B37E;
}

.dialog-input-label {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 93%;
  color: #FFFFFF;
  padding: 20px 0 10px 10px;
}

.resultBox {
  color: #FFFFFF;
  background-color: #2C2C2C;
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  margin: 5px auto;
  width: 545px;
  z-index: 999;
  font-size: 14px;
  line-height: 13px;
  cursor: pointer;
}

.resultBox:hover {
  background-color: #36B37E;
}

.selected-invite-members-wrapper {
  padding-left: 15px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.selected-invite-members {
  color: #FFFFFF;
  list-style-type: none;
  margin: 0;
  font-size: 16px;
  line-height: 15px;
  padding: 0;
}

.selected-invite-member {
  padding-bottom: 20px;
}

.team-admin-mail {
  color: #FFFFFF;
  font-size: 16px;
  line-height: 15px;
  margin: 0;
  padding: 20px 0;
}

.selected-invite-member-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.submit-btn-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 12rem;
  justify-content: center;
}

.profile-action {
  color: #9EA5AD;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 93%;
  cursor: pointer;
}

.profile-action:hover {
  color: #FFFFFF;
}

.btn {
  width: 202px;
  height: 51px;
  border-radius: 24px;
  border: none;
  background-color: #36B37E;
  color: white;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 93%;
  cursor: pointer;
}

.btn:hover {
  background-color: #FFFFFF;
  color: #36B37E;
}

.error-msg {
  background-color: #FF4E4E;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 600;
  line-height: 15px;
  text-align: center;
  padding: 20px;
  border-radius: 16px;
  margin-bottom: 30px;
}

.profile-action {
  color: #9EA5AD;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 93%;
  cursor: pointer;
}

.profile-action:hover {
  color: #FFFFFF;
}

.profile-action-container {
  padding: 5px 0 10px 10px;
}

.delete-container {
  padding-top: 30px;
  padding-bottom: 30px;
}

.confirmation-header {
  color: #FFFFFF;
  margin: 0;
  padding: 0 0 15px 0;
  font-size: 24px;
  line-height: 26px;
}

.confirmation-btn-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 12rem;
  justify-content: center;
}

.confirm-delete {
  color: #9EA5AD;
  font-size: 18px;
}

.add-project {
  cursor: pointer;
}

.loading-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  margin: 4px;
  border: 4px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
