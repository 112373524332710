import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged, setPersistence, browserLocalPersistence, signInWithEmailAndPassword,
    signOut } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyAn5RcFtEZYdd9iDbPeBFmgzwjHf3P278Y",
    authDomain: "code-snippet-manager-d495f.firebaseapp.com",
    databaseURL: "https://code-snippet-manager-d495f-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "code-snippet-manager-d495f",
    storageBucket: "code-snippet-manager-d495f.appspot.com",
    messagingSenderId: "493688266121",
    appId: "1:493688266121:web:1e92872be93f844635d558",
    measurementId: "G-2LYNFZSWM4"
};
const firebaseApp = initializeApp(firebaseConfig);

export const auth = getAuth(firebaseApp);

export async function login(email, password) {
    await setPersistence(auth, browserLocalPersistence);
    await signInWithEmailAndPassword(auth, email, password);
}

export function isLoggedIn() {
    return new Promise(resolve => onAuthStateChanged(auth, user => resolve(!!user)));
}

export async function logout() {
    await signOut(auth);
}
