<template>
  <TheSidebar v-if="showSidebar"/>
  <router-view/>
</template>

<script>
import { auth } from '@/firebaseConfig';
import { onAuthStateChanged } from 'firebase/auth';
import TheSidebar from '@/components/TheSidebar.vue';

export default {
  name: 'App',
  components: {
    TheSidebar
  },
  computed: {
    showSidebar() {
      const routeName = this.$route.name;
      return !['login', 'register', 'forgotPassword', 'auth', 'resetPassword', 'joinTeam'].includes(routeName);
    }
  },
  created() {
    onAuthStateChanged(auth, user => this.$store.dispatch('auth/setUser', user));
  }
}
</script>

<style>
body {
  background-color: #1E1E1E;
}
</style>