<template>
  <div class="body">
    <div class="logo">
      <img style="width: 200px" src="../assets/logo.png">
    </div>
    <div><h1>Code Snippet <br> Manager</h1></div>
    <div>
      <div v-if="error" class="error-msg">{{ error }}</div>
      <form @submit.prevent="login">
        <div>
          <div class="input-fields" style="padding-bottom: 25px">
            <input
                id="email"
                type="email"
                class="form-control"
                name="email"
                placeholder="E-Mail"
                value
                required
                autofocus
                v-model="email"
            />
          </div>
        </div>
        <div>
          <div class="input-fields" style="padding-bottom: 10px">
            <input
                id="password"
                type="password"
                class="form-control"
                name="password"
                placeholder="Password"
                required
                v-model="password"
            />
          </div>
        </div>
        <div>
          <a class="forgot-password" href="#" @click="this.$router.push('forgot-password')">Forgot password?</a>
        </div>
        <div class="btn-wrapper">
          <div class="btn-container">
            <button type="submit">Login</button>
          </div>
          <div class="register-container">
            <a href="#" @click="this.$router.push('register')" class="register-link">Register</a>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="footer-container">
    <footer>
      <div class="footer-link-container">
        <p>
          <a href="https://codesnippetmanager.com/imprint" class="footer-link">Imprint</a> |
          <a href="https://codesnippetmanager.com/privacy-policy" class="footer-link">Privacy Policy</a>
        </p>
      </div>
    </footer>
  </div>
</template>

<script>
import { login } from '@/firebaseConfig';
import router from "@/router";

export default {
  name: 'LoginComponent',
  data() {
    return {
      email: '',
      password: '',
      error: null,
    };
  },
  methods: {
    router() {
      return router
    },
    async login() {
      try {
        await login(this.email, this.password);
        this.$router.replace({ name: 'dashboard' });
      } catch (err) {
        switch (err.code) {
          case 'auth/user-not-found':
            this.error = 'User could not be found. Please check your email and password';
            break;
          case 'auth/wrong-password':
            this.error = 'Wrong password, please try again';
            break;
          default:
            this.error = 'An error has occured. Please try again';
            break;
        }
        console.log(err.code);
      }
    }
  }
};
</script>

<style scoped>
.body {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

h1 {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 36px;
  line-height: 33px;
  margin: 25px 0 35px 0;
  text-align: center;
  color: #FFFFFF;
}

.logo {
  width: 200px;
  margin: 0 auto;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

input {
  height: 2.5rem;
  padding: 0 15px;
  border-radius: 18px;
  width: 414px;
  box-sizing: border-box;
  background-color: #2C2C2C;
  border: none;
}

input, select {
  color: white;
  font-family: "Open Sans", sans-serif;
}

input::placeholder {
  color: #9EA5AD;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  line-height: 17px;
  font-size: 14px;
  font-weight: 400;
}

input:focus-visible {
  outline: 2px solid #36B37E;
  outline-offset: -2px;
}

.input-fields {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.forgot-password {
  margin: 0 0 0 295px;
  font-size: 14px;
  line-height: 17px;
  color: #36B37E;
}

.forgot-password:hover {
  color: #FFFFFF;
}

button {
  width: 202px;
  height: 51px;
  border-radius: 24px;
  border: none;
  background-color: #36B37E;
  color: white;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 93%;
  cursor: pointer;
}

button:hover {
  background-color: #FFFFFF;
  color: #36B37E;
}

.btn-container {
  margin-top: 25px;
}

a {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  text-decoration: none;
  color: white;
}

.register-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.register-link {
  font-size: 18px;
  font-weight: 700;
  color: #FFFFFF;
  margin-top: 15px;
}

.register-link:hover {
  color: #36B37E;
}

.error-msg {
  background-color: #FF4E4E;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 600;
  line-height: 15px;
  text-align: center;
  padding: 20px;
  border-radius: 16px;
  margin-bottom: 30px;
}

footer {
  padding: 15px;
  background-color: #2C2C2C;
  flex-shrink: 0;
}

.footer-link-container {
  color: white;
  font-weight: bold;
  display: flex;
  justify-content: center;
}

.footer-container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.footer-link {
  font-size: 16px;
  cursor: pointer;
}

.footer-link:hover {
  color: #36B37E;
}

.btn-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
}
</style>