<template>
a
</template>

<script>
import router from "@/router";

export default {
  name: "JoinTeam",
  created() {
    this.handleTeamInvite();
  },
  methods: {
    async handleTeamInvite() {
      const userId = this.$route.query.userId;
      const teamId = this.$route.query.teamId;
      const inviteCode = this.$route.query.inviteCode

      const response = await fetch('https://europe-west3-code-snippet-manager-d495f.cloudfunctions.net/api/databasePatchUserStatus', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          teamId: teamId,
          userId: userId,
          inviteCode: inviteCode
        })
      })
      if (response.status === 204) {
        await router.push('/');
      } else {
        await router.push('/login');
      }
    }
  }
}
</script>

<style scoped>

</style>