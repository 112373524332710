export default {
    getCurrentTeams(state) {
        return state.currentTeams;
    },
    getPersonalProjects(state) {
        return state.personalProjects;
    },
    getFavoriteProjects(state) {
        return state.favoriteProjects;
    }
}
