import actions from './actions';
import mutations from './mutations';
import getters from './getters';

export default {
    namespaced: true,
    state() {
        return {
            currentTeams: [],
            personalProjects: [],
            favoriteProjects: []
        };
    },
    actions,
    mutations,
    getters
}
