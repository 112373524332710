export default {
    setCurrentTeams(state, currentTeams) {
        state.currentTeams = currentTeams;
    },
    setPersonalProjects(state, personalProjects) {
        state.personalProjects = personalProjects;
    },
    setFavoriteProjects(state, favoriteProjects) {
        state.favoriteProjects = favoriteProjects;
    }
}
