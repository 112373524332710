<template>
    <div class="AddCodeSnippetArea">
        <img class="back" src="../assets/back.svg" @click="back()">
        <h1 ref="title" class="title" contenteditable="true">{{ title }} </h1>
        <h2>Description</h2>
        <textarea ref="description" class="divi description code" v-model="description"></textarea>
        <br>
        <h2 class="code-block-titel">Code Block</h2>
        <form ref="languageForm" autocomplete="off" action="" @submit="submit">
            <div class="autocomplete" style="width:300px;">
                <input id="myInput" type="text" name="language" placeholder="Language">
            </div>
        </form>
        <textarea ref="codeBlock" class="divi code-block code" wrap='off' v-model="codeContent"></textarea>
        <div class="btn-wrapper">
          <div class="submit-button" @click="submit">Save</div>
        </div>
    </div>


</template>


<script>

export default {
    data() {
        return {
            language: "javascript",
            description: "",
            codeContent: "",
            title: "Add Title Here"
        }
    },

    props: {
        id: String,
        projectId: String,
        snippetId: String

    },
    mounted() {
        //adding the styling libary
        try {
            let delElement = document.getElementById("codeJS");
            delElement.remove();
            delElement = document.getElementById("codeCss");
            delElement.remove();
        } catch (err) {
            console.log("rmv")
        }
        let element = document.createElement('script')
        element.setAttribute('src', 'https://swompygames.com/prism.js')
        element.setAttribute('id', 'codeJS');
        document.head.appendChild(element)
        element = document.createElement('link')
        element.setAttribute('rel', 'stylesheet')
        element.setAttribute('href', 'https://swompygames.com/prism.css')
        element.setAttribute('id', 'codeCss');
        document.head.appendChild(element)

        //allowing tabbing in textarea
        this.$refs.codeBlock.addEventListener('keydown', function (e) {
            if (e.key == 'Tab') {
                e.preventDefault();
                var start = this.selectionStart;
                var end = this.selectionEnd;

                // set textarea value to: text before caret + tab + text after caret
                this.value = this.value.substring(0, start) +
                    "\t" + this.value.substring(end);

                // put caret at right position again
                this.selectionStart =
                    this.selectionEnd = start + 1;
            }
        });

        //adding content if there is any
        if (this.$props.snippetId != "noId") {
            let snippetArray = JSON.parse(localStorage.getItem("codeSnippetarray"))
            let mySnippet = snippetArray.find(function (obj) {
                return obj.snippetId === this.$props.snippetId;
            }.bind(this));
            this.$data.codeContent = mySnippet.codeContent;
            this.$data.description = mySnippet.description;
            this.$data.title = mySnippet.title;
            this.$refs.languageForm.elements.language.value = mySnippet.language;
        }

        var countries = ["html", "markup", "css", "clike", "javascript", "abap", "actionscript", "ada", "apacheconf", "apl", "applescript", "arduino", "arff", "asciidoc", "asm6502", "aspnet", "autohotkey", "autoit", "bash", "basic", "batch", "bison", "brainfuck", "bro", "c", "csharp", "cpp", "coffeescript", "clojure", "crystal", "csp", "css-extras", "d", "dart", "diff", "django", "docker", "eiffel", "elixir", "elm", "erb", "erlang", "fsharp", "flow", "fortran", "gedcom", "gherkin", "git", "glsl", "gml", "go", "graphql", "groovy", "haml", "handlebars", "haskell", "haxe", "http", "hpkp", "hsts", "ichigojam", "icon", "inform7", "ini", "io", "j", "java", "jolie", "json", "julia", "keyman", "kotlin", "latex", "less", "liquid", "lisp", "livescript", "lolcode", "lua", "makefile", "markdown", "markup-templating", "matlab", "mel", "mizar", "monkey", "n4js", "nasm", "nginx", "nim", "nix", "nsis", "objectivec", "ocaml", "opencl", "oz", "parigp", "parser", "pascal", "perl", "php", "php-extras", "plsql", "powershell", "processing", "prolog", "properties", "protobuf", "pug", "puppet", "pure", "python", "q", "qore", "r", "jsx", "tsx", "renpy", "reason", "rest", "rip", "roboconf", "ruby", "rust", "sas", "sass", "scss", "scala", "scheme", "smalltalk", "smarty", "sql", "soy", "stylus", "swift", "tap", "tcl", "textile", "tt2", "twig", "typescript", "vbnet", "velocity", "verilog", "vhdl", "vim", "visual-basic", "wasm", "wiki", "xeora", "xojo", "xquery", "yaml"];
        autocomplete(document.getElementById("myInput"), countries);
        function autocomplete(inp, arr) {
            var currentFocus;
            inp.addEventListener("input", function () {
                var a, b, i, val = this.value;
                closeAllLists();
                if (!val) { return false; }
                currentFocus = -1;
                a = document.createElement("DIV");
                a.setAttribute("id", this.id + "autocomplete-list");
                a.setAttribute("class", "autocomplete-items");
                this.parentNode.appendChild(a);
                for (i = 0; i < arr.length; i++) {
                    if (arr[i].substr(0, val.length).toUpperCase() == val.toUpperCase()) {
                        b = document.createElement("DIV");
                        b.style.cssText = "cursor: pointer;";
                        b.innerHTML = '<strong>' + arr[i].substr(0, val.length) + "</strong>";
                        b.innerHTML += arr[i].substr(val.length);
                        b.innerHTML += "<input type='hidden' value='" + arr[i] + "'>";
                        b.addEventListener("click", function () {
                            inp.value = this.getElementsByTagName("input")[0].value;
                            closeAllLists();
                        });
                        a.appendChild(b);
                    }
                }
            });
            inp.addEventListener("keydown", function (e) {
                var x = document.getElementById(this.id + "autocomplete-list");
                if (x) x = x.getElementsByTagName("div");
                if (e.keyCode == 40) {
                    currentFocus++;
                    addActive(x);
                } else if (e.keyCode == 38) {
                    currentFocus--;
                    addActive(x);
                } else if (e.keyCode == 13) {
                    e.preventDefault();
                    if (currentFocus > -1) {
                        if (x) x[currentFocus].click();
                    }
                }
            });
            function addActive(x) {
                if (!x) return false;
                removeActive(x);
                if (currentFocus >= x.length) currentFocus = 0;
                if (currentFocus < 0) currentFocus = (x.length - 1);
                x[currentFocus].classList.add("autocomplete-active");
            }
            function removeActive(x) {
                for (var i = 0; i < x.length; i++) {
                    x[i].classList.remove("autocomplete-active");
                }
            }
            function closeAllLists(elmnt) {
                var x = document.getElementsByClassName("autocomplete-items");
                for (var i = 0; i < x.length; i++) {
                    if (elmnt != x[i] && elmnt != inp) {
                        x[i].parentNode.removeChild(x[i]);
                    }
                }
            }
            document.addEventListener("click", function (e) {
                closeAllLists(e.target);
            });
        }
    },
    methods: {
        submit: function (event) {
            event.preventDefault();
            let textarea = this.$refs.codeBlock
            let text = textarea.value;
            text = textarea.value.replace(/\r\n|\r|\n/g, '\n');
            if (this.$props.snippetId == "noId") {
                fetch('https://europe-west3-code-snippet-manager-d495f.cloudfunctions.net/api/databasePostCodeSnippet', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        "projectId": this.$props.projectId,
                        "title": this.$refs.title.textContent,
                        "description": this.$refs.description.value,
                        "codeContent": text,
                        "language": this.$refs.languageForm.elements.language.value,
                    })
                })
                    .then(response => response.text())
                    .then(data => {
                        console.log('Success:', data);
                    })
                    .catch((error) => {
                        console.error('Error:', error);
                    });
            }
            else {
                fetch('https://europe-west3-code-snippet-manager-d495f.cloudfunctions.net/api/databasePatchCodeSnippet', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        "snippetId": this.$props.snippetId,
                        "title": this.$refs.title.textContent,
                        "description": this.$refs.description.value,
                        "codeContent": text,
                        "language": this.$refs.languageForm.elements.language.value,
                    })
                })
                    .then(response => response.json())
                    .then(data => {
                        console.log('Success:', data);
                    })
                    .catch((error) => {
                        console.error('Error:', error);
                    });
            }

            history.back()
        },
        back: function () {
            history.back()
        }
    },
    async created() {
        try {
            await this.$store.dispatch('user/loadCurrentTeams');
            await this.$store.dispatch('user/loadPersonalProjects');
            this.$store.dispatch('user/loadFavoriteProjects');
        } catch (err) {
            console.error(err);
        }
    }
}
</script>


<style scoped>
.AddCodeSnippetArea {
    position: absolute;
    left: 370px;
    color: white;
}

.title {
    color: white;
    text-decoration-line: underline;
    float: left;
    font-weight: 600;
    font-size: 32px;
    box-sizing: border-box;
}

.title:hover{
    outline: 2px solid #fff;
    border-radius: 2px;
    outline-offset: 10px;
}

[contenteditable] {
  outline: 0px solid transparent;
}



.back {
    height: 30px;
    color: white;
    float: left;
    margin-top: 25px;
    margin-right: 20px;
    cursor: pointer;
}

.code-block-titel{
    margin-top: 40px;
}

h2 {
    color: #9EA5AD;
    font-weight: 24px;
    margin-top: 100px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 93%;
}

.submit-button {
    width: 202px;
    height: 51px;
    background-color: #36B37E;
    margin-top: 20px;
    border-radius: 24px;
    cursor: pointer;
    text-align: center;
    vertical-align: middle;
    line-height: 51px;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
}

.submit-button:hover {
  background-color: #FFFFFF;
  color: #36B37E;
}

.divi {
    font-weight: 400;
    font-size: 35px;
    text-align: left;
    border-radius: 16px;
}

.code {
    border-style: none;
    box-shadow: none;
    width: 100%;
    height: 100%;
}

#show-box {
    width: 30px;
    height: 30px;
    accent-color: #2C2C2C;
    margin-top: 20px;
    margin-bottom: 40px;
    border-radius: 10px;
    cursor: pointer;
}

label {
    padding-top: 40px;
}

.description {
    font-size: 16px;
    resize: none;
    width: 1400px;
    height: 180px;
    padding: 12px 20px;
    border: 16px solid #2C2C2C;
    background-color: #2C2C2C;
    border-radius: 16px;
    overflow: auto;
}

.code-block {
    font-size: 16px;
    resize: none;
    width: 1400px;
    height: 300px;
    padding: 12px 20px;
    margin-top: 20px;
    border: 16px solid #2C2C2C;
    background-color: #2C2C2C;
    border-radius: 16px;
    overflow: auto;
}

* {
    box-sizing: border-box;
}

body {
    font: 16px Arial;
}

.autocomplete {
    position: relative;
    display: inline-block;
}

input {
    border: 1px solid transparent;
    background-color: #2C2C2C;;
    padding: 10px;
    font-size: 16px;
    border-radius: 13px;
}

input[type=text] {
    background-color: #2C2C2C;;
    width: 100%;
    color:#fff;
}


input[type=submit] {
    background-color: #36B37E;
    color: #2C2C2C;
}

.autocomplete-items {
    position: absolute;
    border: 1px solid #d4d4d4;
    border-bottom: none;
    border-top: none;
    z-index: 99;
    top: 100%;
    left: 0;
    right: 0;
}

.autocomplete-items div {
    padding: 10px;
    cursor: pointer;
    background-color: #fff;
    border-bottom: 1px solid #d4d4d4;
}

.autocomplete-items div:hover {
    background-color: #e9e9e9;
}

.autocomplete-active {
    background-color: #36B37E !important;
    color: #ffffff;
}

textarea {
    color: #fff;
}
</style>