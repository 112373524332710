export default {
    getUser(state) {
        return state.user;
    },
    getUserId(state, getters) {
        return getters.getUser.uid;
    },
    getUserEmail(state, getters) {
        return getters.getUser.email;
    },
    isLoggedIn(state, getters) {
        return !!getters.getUser;
    }
}