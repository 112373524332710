<template>
  <div ref="draggableContainer" class="divi draggable-container" :style="{ top: setTop + 'px', left: setLeft + 'px' }">
    <div class="divi draggable-header">
      <txt class="title">{{ title }}</txt>
      <img id="drag" class="image" src="../assets/codesnippet/drag.png" @mousedown="dragMouseDown"
        @mouseup="dragMouseUp">
      <img class="image" src="../assets/codesnippet/copy.png" @click="clickCopy" v-if="!toggleCopied">
      <img class="image" src="../assets/codesnippet/copied.png" @click="clickCopy" v-if="toggleCopied">
      <img class="image" src="../assets/codesnippet/edit.png" @click="gotoEdit">
      <img class="image" src="../assets/codesnippet/delete.png" @click="deleteSnippet">
      <img class="image info" src="../assets/codesnippet/info.png" @click="descriptionSetter" v-if="!toggleDescription">
      <img class="image info" src="../assets/codesnippet/code.png" @click="descriptionSetter" v-if="toggleDescription">
      <p id="date">{{ date }}</p>
    </div>
    <div class="divi blackBorder"></div>
    <div ref="textField" class="divi textField code" v-show="!toggleDescription">
      <pre :class="'language-' + language"><code ref="code"></code></pre>
    </div>
    <div ref="textField" class="divi textField code description" v-if="toggleDescription">
      {{ description }}
    </div>
  </div>
  <div ref="box" class="box"></div>
  <div ref="line" class="line"></div>
</template>
<script>
import { codeSnippetSysthem } from '../../globalVar.js'

export default {
  data() {
    return {
      positions: {
        clientX: undefined,
        clientY: undefined,
        movementX: 0,
        movementY: 0,
      },
      showBox: false,
      toggleDescription: false,
      toggleCopied: false,
      boxTop: 0,
      boxLeft: 0,
    }
  },

  props: {
    setTop: String,
    setLeft: String,
    codeSnippetPosition: Number,
    title: String,
    codeContent: String,
    date: String,
    language: String,
    snippetId: String,
    projectId: String,
    description: String,
  },
  mounted() {
    /* eslint-disable no-undef */
    //Prism.highlightAll();
    let formattedCode = this.$props.codeContent.replace(/\\n/g, "\n");
    this.$refs.code.textContent = formattedCode;
  },
  methods: {
    descriptionSetter: function () {
      if (this.toggleDescription == false) {
        this.toggleDescription = true;
      }
      else {
        this.toggleDescription = false;
      }
    },
    deleteSnippet: function () {
      fetch('https://europe-west3-code-snippet-manager-d495f.cloudfunctions.net/api/databaseDeleteCodeSnippet', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          "snippetId": this.$props.snippetId,
        })
      })
        .then(response => response.text())
        .then(data => {
          location.reload();
          console.log(data)
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    },
    dragMouseDown: function (event) {
      event.preventDefault()
      // get the mouse cursor position at startup:
      this.positions.clientX = event.clientX
      this.positions.clientY = event.clientY
      document.onmousemove = this.elementDrag
      document.onmouseup = this.closeDragElement
    },
    elementDrag: function (event) {
      event.preventDefault()
      this.$refs.draggableContainer.style.zIndex = "9";
      this.positions.movementX = this.positions.clientX - event.clientX
      this.positions.movementY = this.positions.clientY - event.clientY
      this.positions.clientX = event.clientX
      this.positions.clientY = event.clientY

      // set the element's new position:
      this.$refs.draggableContainer.style.left = (this.$refs.draggableContainer.offsetLeft - this.positions.movementX) + 'px'
      this.$refs.draggableContainer.style.top = (this.$refs.draggableContainer.offsetTop - this.positions.movementY) + 'px'
      //the higlight box
      let _step = codeSnippetSysthem.rasterStep
      let _positionX = this.$refs.draggableContainer.offsetLeft - this.positions.movementX - codeSnippetSysthem.startX
      let _positionY = this.$refs.draggableContainer.offsetTop - this.positions.movementY - codeSnippetSysthem.startY
      let _cancel = false;
      let _swapRow = ((_positionX + _step / 2) - (_positionX + _step / 2) % _step) / _step
      if (_swapRow > 3) {
        _cancel = true;
      }
      let _swapSplit = Math.round(_positionY / codeSnippetSysthem.rowStep)
      let _swapPosition = _swapSplit * 3 + _swapRow;
      if (_cancel == true) {
        this.showBox = false;
      }
      let _box_width = codeSnippetSysthem.rasterStep;
      if ((_positionX % _box_width < _box_width * 0.6 && _positionX % _box_width > _box_width * 0.4) || _positionX < 0) {
        _positionX = this.$refs.draggableContainer.offsetLeft - this.positions.movementX - codeSnippetSysthem.startX - 250
        _positionY = this.$refs.draggableContainer.offsetTop - this.positions.movementY - codeSnippetSysthem.startY
        _swapRow = ((_positionX + _step / 2) - (_positionX + _step / 2) % _step) / _step
        if (_swapRow > 3) {
          _cancel = true;
        }
        _swapSplit = Math.round(_positionY / codeSnippetSysthem.rowStep)
        _swapPosition = _swapSplit * 3 + _swapRow;
        this.$refs.line.style.left = codeSnippetSysthem.startX + (_swapPosition % codeSnippetSysthem.rowAmount) * codeSnippetSysthem.rasterStep + _box_width * 0.95 + 'px';
        this.$refs.line.style.top = codeSnippetSysthem.startY + Math.floor(_swapPosition / codeSnippetSysthem.rowAmount) * codeSnippetSysthem.rowStep + 'px';
        this.$refs.line.style.opacity = 1;
        this.$refs.box.style.opacity = 0;
        if (_positionX + 250 < 0) {
          this.$refs.line.style.left = codeSnippetSysthem.startX + (_swapPosition % codeSnippetSysthem.rowAmount) * codeSnippetSysthem.rasterStep - _box_width * 0.05 + 'px';
        }
      }
      else {
        this.$refs.box.style.left = codeSnippetSysthem.startX + (_swapPosition % codeSnippetSysthem.rowAmount) * codeSnippetSysthem.rasterStep + 'px';
        this.$refs.box.style.top = codeSnippetSysthem.startY + Math.floor(_swapPosition / codeSnippetSysthem.rowAmount) * codeSnippetSysthem.rowStep + 'px';
        this.$refs.line.style.opacity = 0;
        this.$refs.box.style.opacity = 1;
      }

    },
    closeDragElement() {
      document.onmouseup = null
      document.onmousemove = null
    },
    dragMouseUp: function () {
      codeSnippetSysthem.checkUpdate = true;
      let _step = codeSnippetSysthem.rasterStep
      let _positionX = this.$refs.draggableContainer.offsetLeft - this.positions.movementX - codeSnippetSysthem.startX
      let _positionY = this.$refs.draggableContainer.offsetTop - this.positions.movementY - codeSnippetSysthem.startY
      let _cancel = false;
      let _swapRow = ((_positionX + _step / 2) - (_positionX + _step / 2) % _step) / _step
      if (_swapRow > 3) {
        _cancel = true;
      }
      let _swapSplit = Math.round(_positionY / codeSnippetSysthem.rowStep)
      let _swapPosition = _swapSplit * 3 + _swapRow;
      let _swapItem = codeSnippetSysthem.codeSnippetarray[_swapPosition];
      if (_swapItem == undefined) {
        _cancel = true;
      }
      if (_cancel == false) {
        let _box_width = codeSnippetSysthem.rasterStep;
        if ((_positionX % _box_width < _box_width * 0.6 && _positionX % _box_width > _box_width * 0.4) || _positionX < 0) {
          _positionX = this.$refs.draggableContainer.offsetLeft - this.positions.movementX - codeSnippetSysthem.startX - 250
          _positionY = this.$refs.draggableContainer.offsetTop - this.positions.movementY - codeSnippetSysthem.startY
          _swapRow = ((_positionX + _step / 2) - (_positionX + _step / 2) % _step) / _step
          if (_swapRow > 3) {
            _cancel = true;
          }
          _swapSplit = Math.round(_positionY / codeSnippetSysthem.rowStep)
          _swapPosition = _swapSplit * 3 + _swapRow;
          _positionY = this.$refs.draggableContainer.offsetTop - this.positions.movementY - codeSnippetSysthem.startY - 250
          let _newIndex = _swapPosition + 1;
          if (_positionX + 250 < 0) {
            _newIndex = 0;
          }
          let _oldIndex = this.codeSnippetPosition;
          if (_newIndex >= codeSnippetSysthem.codeSnippetarray.length) {
            var k = _newIndex - codeSnippetSysthem.codeSnippetarray.length + 1;
            while (k--) {
              codeSnippetSysthem.codeSnippetarray.push(undefined);
            }
          }
          console.log(_newIndex)
          console.log(_oldIndex)
          codeSnippetSysthem.codeSnippetarray.splice(_newIndex, 0, codeSnippetSysthem.codeSnippetarray.splice(_oldIndex, 1)[0]);

        }
        else {
          console.log(2)
          codeSnippetSysthem.codeSnippetarray[_swapPosition] = codeSnippetSysthem.codeSnippetarray[this.codeSnippetPosition];
          codeSnippetSysthem.codeSnippetarray[this.codeSnippetPosition] = _swapItem;
        }
      }
      let sortArr = codeSnippetSysthem.codeSnippetarray.map(function (obj) {
        return obj.snippetId;
      });
      //sending the api the new order
      console.log(codeSnippetSysthem.isSolo)
      fetch('https://europe-west3-code-snippet-manager-d495f.cloudfunctions.net/api/databasePatchCodeSnippetOrder', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          "mode": codeSnippetSysthem.isSolo,
          "projectId": this.$props.projectId,
          "codeSnippetOrder": sortArr,
          "userId": codeSnippetSysthem.userId,
        })
      })
        .then(response => response.json())
        .then(data => {
          console.log('Success:', data);
        })
        .catch((error) => {
          console.error('Error:', error);
        });
      this.$refs.draggableContainer.style.zIndex = "8";
    },
    clickCopy: function () {
      this.toggleCopied = true;
      setTimeout(() => {
        this.toggleCopied = false;
      }, 3000);
      navigator.clipboard.writeText(this.$refs.textField.textContent)
    },
    gotoEdit: function () {
      window.location.href = window.location.href + "/add?snippetId=" + this.$props.snippetId;
    },
  }

}
</script>


<style>
.description {
  color: #fff;
}

.divi {
  font-weight: 700;
  font-size: 35px;
  text-align: left;
  border-radius: 16px 16px 0 0;
}

.blackBorder {
  border: 1px solid #1E1E1E;
}

#date {
  font-weight: 400;
  font-size: 12px;
  margin-top: 5px;
  margin-bottom: -5px;
  color: #9EA5AD;
  margin-left: 8px;
  margin-top: 0px;
  margin-bottom: 3px;
}

.code {
  border-style: none;
  box-shadow: none;
}

.textField {
  font-size: 12px;
  resize: none;
  width: 400px;
  height: 200px;
  padding: 12px 20px;
  border: 16px solid #2C2C2C;
  background-color: #1E1E1E;
  background: #1E1E1E;
  border-radius: 0px 0px 16px 16px;
  overflow: auto;
}

.draggable-container {
  position: absolute;
  text-align: center;
  z-index: 8;
}


.draggable-header {
  padding: 10px;
  background-color: #2C2C2C;
  color: #fff;
}

.image {
  float: right;
  width: 20px;
  margin-left: 5px;
  cursor: pointer;
}

#drag {
  cursor: move;
}

.title {
  font-weight: 700;
  font-size: 24px;
  line-height: 93%;
  margin-left: 8px;
}

.box {
  opacity: 0;
  position: absolute;
  z-index: 8;
  border: 1px solid #36B37E;
  box-shadow: 0 0 20px rgba(54, 179, 126, 0.8);
  border-radius: 16px 16px 16px 16px;
  pointer-events: none;
  width: 470px;
  height: 340px;
  left: 0;
  top: 0;
}

.line {
  opacity: 0;
  position: absolute;
  z-index: 8;
  border: 1px solid #36B37E;
  box-shadow: 0 0 20px rgba(54, 179, 126, 0.8);
  border-radius: 16px 16px 16px 16px;
  pointer-events: none;
  width: 20px;
  height: 340px;
  left: 0;
  top: 0;
}
</style>