<template>
a
</template>

<script>
import {auth} from "@/firebaseConfig";
import {createUserWithEmailAndPassword, updateProfile} from "firebase/auth";
import router from "@/router";

export default {
  // eslint-disable-next-line
  name: "Auth",
  created() {
    this.redirectByAuthMode();
  },
  methods: {
    redirectByAuthMode() {
      const authMode = this.$route.query.mode;
      // eslint-disable-next-line no-use-before-define
      const email = this.$route.query.email;
      // eslint-disable-next-line no-use-before-define
      const password = this.$route.query.password;
      const name = this.$route.query.name;
      switch (authMode) {
        case 'resetPassword': this.redirectToAuthPage({ authPageName: 'resetPassword' }); break;
        case 'recoverEmail': this.redirectToAuthPage({ authPageName: 'changeEmail' }); break;
        case 'verifyEmail': this.registerUser(email, password, name); break;
        default: throw new Error('authMode is invalid!');
      }
    },
    redirectToAuthPage({ authPageName }) {
      const authCode = this.$route.query.oobCode;

      this.$router.replace({
        name: authPageName,
        query: { authCode }
      });
    },
    async registerUser(email, password, name) {
      const formattedName = name.replaceAll('%20', ' ')

      const userCredential = await createUserWithEmailAndPassword(auth, email, password).catch((error => {
        console.error(error);
      }))
      await updateProfile(userCredential.user, {
        displayName: formattedName
      })

      await router.push('/login')
    }
  }
}
</script>

<style scoped>

</style>