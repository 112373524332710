<template>
  <div class="body">
    <div class="logo">
      <img style="width: 200px" src="../assets/logo.png" alt="logo">
    </div>
    <div>
      <h1>Code Snippet <br> Manager</h1>
    </div>
    <div>
      <div v-if="error" class="error-msg">{{ error }}</div>
      <div v-if="success" class="success-msg">
        Password reset mail has been sent. Please check your inbox <br> in order to reset your password
      </div>
      <div class="instructions">Please enter your e-mail adress. If it can be associated with a user account, we will send you an email to reset your password.</div>
      <form action="#" @submit.prevent="forgotPassword">
        <div>
          <div class="input-fields" style="padding-bottom: 10px">
            <input
                id="email"
                type="email"
                class="form-control"
                name="email"
                placeholder="E-Mail"
                value
                required
                autofocus
                v-model="email"
            />
          </div>
        </div>
        <div class="btn-wrapper">
          <div class="btn-container">
            <div class="btn-loading">
              <div v-if="this.loadingStatus === 'LOADING'" class="loading-animation">
                <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
              </div>
              <button type="submit">Send e-mail</button>
            </div>
          </div>
          <div class="login-container">
            <a href="#" @click="this.$router.push('login')" class="login-link">Login</a>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="footer-container">
    <footer>
      <div class="footer-link-container">
        <a href="https://codesnippetmanager.com/imprint" class="footer-link">Imprint</a> |
        <a href="https://codesnippetmanager.com/privacy-policy" class="footer-link">Privacy Policy</a>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "ForgotPassword",
  data() {
    return {
      loadingStatus: 'LOADED',
      success: false,
    }
  },
  methods: {
    async forgotPassword() {
      this.loadingStatus = 'LOADING';
      const email = document.querySelector('#email').value.trim()

      const response = await fetch('https://europe-west3-code-snippet-manager-d495f.cloudfunctions.net/api/resetPassword', {
        method: 'POST',
        mode: "cors",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email: email })
      });
      if (response.status === 200 || response.status === 204) {
        this.loadingStatus = 'LOADED';
        this.success = true
      }
    }
  }
};
</script>

<style scoped>
@import "../styles/style.css";

.body {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

h1 {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 36px;
  line-height: 33px;
  margin: 25px 0 35px 0;
  text-align: center;
  color: #FFFFFF;
}

.logo {
  width: 200px;
  margin: 0 auto;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

input {
  height: 2.5rem;
  padding: 0 15px;
  border-radius: 18px;
  width: 414px;
  box-sizing: border-box;
  background-color: #2C2C2C;
  border: none;
}

input,select {
  color: white;
  font-family: "Open Sans", sans-serif;
}

input::placeholder {
  color: #9EA5AD;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  line-height: 17px;
  font-size: 14px;
  font-weight: 400;
}

input:focus-visible {
  outline: 2px solid #36B37E;
  outline-offset: -2px;
}

.input-fields {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

button {
  width: 202px;
  height: 51px;
  border-radius: 24px;
  border: none;
  background-color: #36B37E;
  color: white;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 93%;
  cursor: pointer;
}

button:hover {
  background-color: #FFFFFF;
  color: #36B37E;
}

.btn-container {
  margin-top: 25px;
}

a {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  text-decoration: none;
  color: white;
}

.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.login-link {
  font-size: 18px;
  font-weight: 700;
  color: #FFFFFF;
  margin-top: 15px;
}

.login-link:hover {
  color: #36B37E;
}

.error-msg {
  color: #FF5F56;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 120%;
  margin: 0 0 5px 15px;
}

footer {
  padding: 15px;
  background-color: #2C2C2C;
  flex-shrink: 0;
}

.footer-link-container {
  color: white;
  font-weight: bold;
  display: flex;
  justify-content: center;
}

.footer-container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.footer-link {
  font-size: 16px;
  cursor: pointer;
}

.footer-link:hover {
  color: #36B37E;
}

.btn-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.instructions {
  color: white;
  width: 350px;
  font-size: 14px;
  line-height: 17px;
  margin: 0 0 20px 15px;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  margin: 4px;
  border: 4px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.btn-loading {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.success-msg {
  background-color: #36B37E;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 600;
  line-height: 15px;
  text-align: center;
  padding: 20px;
  border-radius: 16px;
  margin-bottom: 30px;
}
</style>