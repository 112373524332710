<template>
  <div v-if="localIsVisible" class="dialog-container">
    <div @click="handleClick" class="dialog-backdrop"></div>
    <div class="dialog" ref="dialog">
      <slot></slot>
      <button class="close-button" @click="hideDialog">
        <img src="../assets/close-icon.svg" alt="Close" />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseDialog",
  props: {
    isVisible: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    localIsVisible: {
      get() {
        return this.isVisible;
      },
      set(value) {
        this.$emit('update:isVisible', value);
      }
    }
  },
  methods: {
    showDialog() {
      this.localIsVisible = true;
      this.$emit('show');
    },
    hideDialog() {
      this.localIsVisible = false;
      this.$emit('hide');
    },
    handleClick(event) {
      if (this.$refs.dialog && !this.$refs.dialog.contains(event.target)) {
        this.hideDialog();
      }
    }
  }
}
</script>

<style scoped>
.dialog-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.dialog-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.dialog {
  position: relative;
  width: 565px;
  padding: 40px;
  background: #1E1E1E;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background-color: transparent;
  padding: 0;
}

.close-button img {
  width: 24px;
  height: 24px;
  object-fit: cover;
  cursor: pointer;
}

</style>